// EventAdmin.js
// This component provides a two-column layout:
// Left column: A list of events with a "Create New Event" button
// Right column: Displays either a detailed view of the selected event or a form to create/edit an event.
// When viewing an event, you see all fields including Labels, Partners, and Locations.
// When editing, these fields are pre-populated with the event's current values.

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

const EventAdmin = () => {
  const [events, setEvents] = useState([]);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [selectedEventData, setSelectedEventData] = useState(null); 
  const [mode, setMode] = useState('view'); // 'view', 'create', 'edit'

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    try {
      const res = await axios.get('/api/operation_admin/events/');
      setEvents(res.data);
    } catch (err) {
      console.error('Error fetching events:', err);
    }
  };

  const handleSelectEvent = async (eventId) => {
    setMode('view');
    setSelectedEventId(eventId);
    try {
      const res = await axios.get(`/api/operation_admin/events/${eventId}/`);
      setSelectedEventData(res.data);
    } catch (err) {
      console.error('Error fetching event detail:', err);
    }
  };

  const handleCreateNewEvent = () => {
    setMode('create');
    setSelectedEventId(null);
    setSelectedEventData(null);
  };

  const handleEventCreated = (newEvent) => {
    // Add new event to list
    setEvents([newEvent, ...events]);
    // Select this event and view details
    setSelectedEventId(newEvent.id);
    setSelectedEventData(newEvent);
    setMode('view');
  };

  const handleEventUpdated = (updatedEvent) => {
    // Update in list
    const updatedEvents = events.map(ev => ev.id === updatedEvent.id ? updatedEvent : ev);
    setEvents(updatedEvents);
    // Show updated detail
    setSelectedEventData(updatedEvent);
    setMode('view');
  };

  const handleEditEvent = () => {
    setMode('edit');
  };

  return (
    <div style={{ display: 'flex', height: '100%' }}>
      <div style={{ width: '300px', borderRight: '1px solid #ccc', padding: '10px' }}>
        <EventList
          events={events}
          onSelectEvent={handleSelectEvent}
          onCreateNew={handleCreateNewEvent}
          selectedEventId={selectedEventId}
        />
      </div>
      <div style={{ flex: 1, padding: '10px' }}>
        <EventDetail
          mode={mode}
          eventData={selectedEventData}
          onEventCreated={handleEventCreated}
          onEventUpdated={handleEventUpdated}
          onEdit={handleEditEvent}
        />
      </div>
    </div>
  );
};

export default EventAdmin;

const EventList = ({ events, onSelectEvent, onCreateNew, selectedEventId }) => {
  return (
    <div>
      <button onClick={onCreateNew} style={{ marginBottom: '20px' }}>Create New Event</button>
      <ul style={{ listStyle: 'none', padding: 0 }}>
        {events.map(ev => (
          <li 
            key={ev.id} 
            onClick={() => onSelectEvent(ev.id)}
            style={{
              padding: '10px',
              cursor: 'pointer',
              backgroundColor: ev.id === selectedEventId ? '#eee' : '#fff'
            }}
          >
            {ev.event_name || `Event ${ev.id}`}
          </li>
        ))}
      </ul>
    </div>
  );
};

const EventDetail = ({ mode, eventData, onEventCreated, onEventUpdated, onEdit }) => {
  if (mode === 'view') {
    if (!eventData) {
      return <div>Select an event from the left or create a new one.</div>;
    }
    return (
      <div>
        <h2>{eventData.event_name}</h2>
        <p>Week No: {eventData.week_no}</p>
        <p>Program: {eventData.program}</p>
        <p>Date: {eventData.date}</p>
        <p>Color: <span style={{ backgroundColor: eventData.color, padding: '0 10px' }}>{eventData.color}</span></p>
        <p>Poster: {eventData.event_poster}</p>
        <p>Background: {eventData.event_bg}</p>
        <p>Labels: {eventData.label?.map(l => l.label_name).join(', ')}</p>
        <p>Partners: {eventData.partner?.map(p => p.name).join(', ')}</p>
        <p>Locations: {eventData.location?.map(loc => loc.location_name).join(', ')}</p>

        <button onClick={onEdit}>Edit</button>
      </div>
    );
  }

  return (
    <EventForm
      initialData={mode === 'edit' ? eventData : null}
      onCreated={onEventCreated}
      onUpdated={onEventUpdated}
      mode={mode}
    />
  );
};

const EventForm = ({ initialData, onCreated, onUpdated, mode }) => {
  const [weekNo, setWeekNo] = useState('');
  const [eventName, setEventName] = useState('');
  const [program, setProgram] = useState('rs');
  const [date, setDate] = useState('');
  const [labels, setLabels] = useState([]);
  const [selectedLabels, setSelectedLabels] = useState([]);
  const [partners, setPartners] = useState([]);
  const [selectedPartners, setSelectedPartners] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [color, setColor] = useState('#ffffff');
  const [eventPoster, setEventPoster] = useState('');
  const [eventBg, setEventBg] = useState('');
  const [times, setTimes] = useState([]);

  const programOptions = [
    { value: 'rs', label: 'byyb.radio' },
    { value: 'ia', label: 'ivy arum session' },
    { value: 'hr', label: 'heimliday radio' },
    { value: 'bo', label: 'byyb.out' },
  ];

  // Fetch label, partner, location options
  useEffect(() => {
    axios.get('/api/operation_admin/labels/').then(res => setLabels(res.data));
    axios.get('/api/operation_admin/partners/').then(res => setPartners(res.data));
    axios.get('/api/operation_admin/locations/').then(res => setLocations(res.data));
  }, []);

  // Populate form in edit mode
  useEffect(() => {
    if (initialData && mode === 'edit') {
      setWeekNo(initialData.week_no || '');
      setEventName(initialData.event_name || '');
      setProgram(initialData.program || 'rs');
      setDate(initialData.date || '');
      setColor(initialData.color || '#ffffff');
      setEventPoster(initialData.event_poster || '');
      setEventBg(initialData.event_bg || '');

      // Extract IDs for M2M fields
      setSelectedLabels(initialData.label ? initialData.label.map(l => l.id) : []);
      setSelectedPartners(initialData.partner ? initialData.partner.map(p => p.id) : []);
      setSelectedLocations(initialData.location ? initialData.location.map(loc => loc.id) : []);

      // If you need to handle times in edit mode, fetch them if they are included in initialData
      // Assuming times are not returned by the API currently. If they are, set them here.
      setTimes([]);
    } else if (mode === 'create') {
      // Reset form
      setWeekNo('');
      setEventName('');
      setProgram('rs');
      setDate('');
      setColor('#ffffff');
      setEventPoster('');
      setEventBg('');
      setSelectedLabels([]);
      setSelectedPartners([]);
      setSelectedLocations([]);
      setTimes([]);
    }
  }, [initialData, mode]);

  const handleAddTime = () => {
    setTimes([...times, '']);
  };

  const handleTimeChange = (index, value) => {
    const updatedTimes = [...times];
    updatedTimes[index] = value;
    setTimes(updatedTimes);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      week_no: weekNo ? parseInt(weekNo, 10) : null,
      event_name: eventName,
      program: program,
      date: date,
      label_ids: selectedLabels,
      partner_ids: selectedPartners,
      location_ids: selectedLocations,
      color: color,
      event_poster: eventPoster || null,
      event_bg: eventBg || null
    };

    if (mode === 'create') {
      payload.times = times.filter(t => t !== '');
    } else if (mode === 'edit') {
      // If editing times is desired, include them here. Otherwise, omit times if not needed.
      payload.times = times.filter(t => t !== '');
    }

    try {
      if (mode === 'create') {
        const res = await axios.post('/api/operation_admin/events/', payload);
        toast.success('Event created successfully!');
        onCreated(res.data);
      } else {
        const res = await axios.patch(`/api/operation_admin/events/${initialData.id}/`, payload);
        toast.success('Event updated successfully!');
        onUpdated(res.data);
      }
    } catch (err) {
      console.error(mode === 'create' ? 'Error creating event:' : 'Error updating event:', err);
      toast.error('Failed to save event. Check console for details.');
    }
  };

  return (
    <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', maxWidth: '400px' }}>
      <label>Week No:</label>
      <input type="number" value={weekNo} onChange={e => setWeekNo(e.target.value)} placeholder="e.g. 12" />

      <label>Event Name:</label>
      <input type="text" value={eventName} onChange={e => setEventName(e.target.value)} placeholder="Event Name" />

      <label>Program:</label>
      <select value={program} onChange={e => setProgram(e.target.value)}>
        {programOptions.map(opt => <option key={opt.value} value={opt.value}>{opt.label}</option>)}
      </select>

      <label>Date:</label>
      <input type="date" value={date} onChange={e => setDate(e.target.value)} />

      <label>Labels:</label>
      <select
        multiple
        value={selectedLabels}
        onChange={e => setSelectedLabels(Array.from(e.target.selectedOptions, opt => parseInt(opt.value)))}
      >
        {labels.map(label => <option key={label.id} value={label.id}>{label.label_name}</option>)}
      </select>

      <label>Partners:</label>
      <select
        multiple
        value={selectedPartners}
        onChange={e => setSelectedPartners(Array.from(e.target.selectedOptions, opt => parseInt(opt.value)))}
      >
        {partners.map(partner => <option key={partner.id} value={partner.id}>{partner.name}</option>)}
      </select>

      <label>Locations:</label>
      <select
        multiple
        value={selectedLocations}
        onChange={e => setSelectedLocations(Array.from(e.target.selectedOptions, opt => parseInt(opt.value)))}
      >
        {locations.map(loc => <option key={loc.id} value={loc.id}>{loc.location_name}</option>)}
      </select>

      <label>Color (Hex):</label>
      <input type="text" value={color} onChange={e => setColor(e.target.value)} placeholder="#ffffff" />

      <label>Event Poster URL:</label>
      <input type="url" value={eventPoster} onChange={e => setEventPoster(e.target.value)} placeholder="https://..." />

      <label>Event Background URL:</label>
      <input type="url" value={eventBg} onChange={e => setEventBg(e.target.value)} placeholder="https://..." />

      <h3>Timeslots</h3>
      {times.map((t, idx) => (
        <div key={idx}>
          <input
            type="time"
            value={t}
            onChange={e => handleTimeChange(idx, e.target.value)}
            placeholder="HH:MM"
          />
        </div>
      ))}
      <button type="button" onClick={handleAddTime}>Add Another Timeslot</button>

      <button type="submit" style={{ marginTop: '20px' }}>
        {mode === 'create' ? 'Create Event' : 'Save Changes'}
      </button>
    </form>
  );
};
