import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  format as formatDate,
  parseISO,
  addDays,
  isAfter,
  isBefore,
} from 'date-fns';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import './BookingDetail.css';

/** MUI imports */
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

/** Translations */
const translations = {
  zh: {
    pageTitle: '预约详情',
    // Upload Option Box
    uploadBoxTitle: '是否将本次录音上传到各大平台？',
    /* 
     * Updated text => actual publish_date displayed.
     * We'll do string replacement: 
     * e.g. uploadBoxDesc: '（仅可选择一次), 我们将会在 【{publishDate}】发布 full set 至各个平台 (youtube, soundcloud, bilibili)，以及我们的官网首页。如果对本次演出不满意也没关系...'
     */
    uploadBoxDesc:
      '（仅可选择一次), 我们将会在 【{publishDate}】发布 full set 至各个平台 (YouTube, SoundCloud, Bilibili)，以及我们的官网首页。如果对本次演出不满意也没关系，欢迎下次再来做客！如有任何意见和建议，请致信 byyb.radio@gmail.com！(默认为上传）',

    uploadYes: '可以上传！',
    uploadNo: '这次先不上传了',
    // Section Titles
    section1Title: '电台做客信息',
    section2Title: '宣发物料',
    section3Title: '素材反馈',
    // Fields
    eventNameLabel: '活动名称',
    djNameLabel: '嘉宾艺名',
    dateLabel: '日期',
    timeLabel: '时间',
    locationLabel: '地点',
    mixerModelLabel: '混音台型号',
    genresLabel: '风格',
    noGenres: '暂无',
    baiduLinkLabel: '百度下载链接',
    youtubeLinkLabel: 'YouTube 视频链接',
    highlightSnippetsLabel: '演出高光',
    stillProcessing: '仍在处理，请再等一下 🪴',
    // Buttons
    addUpdateGenresBtn: '新增/更新风格',
    changeBtn: '修改',
    saveBtn: '保存',
    cancelBtn: '取消',
    cancelBooking: '取消预约',
    // Info: upcoming/past
    upcomingInfo: [
      '请提前至少10分钟到场。',
      '每位嘉宾可获得1杯免费饮品或咖啡。',
      '我们也提供热水。',
      '场内周边享7折优惠！',
    ],
    // This text is now only shown at the bottom, after “素材反馈”, if incomplete
    incompleteInfo: '请等待约10天来处理录音，如有碰到问题，欢迎致信 byyb.radio@gmail.com!',
    // Errors/Messages
    noBookingFound: '未找到该预约或无访问权限。',
    loadingBooking: '加载预约详情...',
    areYouSureCancel: '确定取消预约吗？',
    actionFinalConfirm: '此操作将无法恢复。确定继续吗？',
    bookingCanceled: '预约已取消。',
    errorCancelBooking: '取消预约时发生错误。',
    errorLoadBooking: '加载预约详情时出现错误。',
    errorGeneric: '出现错误。',
  },
  en: {
    pageTitle: 'Booking Detail',
    // Upload Option Box
    uploadBoxTitle: 'Do you want us to upload this recording?',
    uploadBoxDesc:
      '(Only once) We will publish the full set on {publishDate} to all platforms (YouTube, SoundCloud, Bilibili) and our official website homepage. If you’re not satisfied with this performance, that’s okay! join us next time! For any comments or suggestions, please email byyb.radio@gmail.com! (we\'ll upload by default)',
    uploadYes: 'Yes, please upload',
    uploadNo: 'No, skip this time',
    // Section Titles
    section1Title: 'Radio Guest Info',
    section2Title: 'Promotional Materials',
    section3Title: 'Media Feedback',
    // Fields
    eventNameLabel: 'Event Name',
    djNameLabel: 'DJ Stage Name',
    dateLabel: 'Date',
    timeLabel: 'Time',
    locationLabel: 'Location',
    mixerModelLabel: 'Mixer Model',
    genresLabel: 'Genres',
    noGenres: 'No genres',
    baiduLinkLabel: 'Baidu Download Link',
    youtubeLinkLabel: 'YouTube Video Link',
    highlightSnippetsLabel: 'Highlight Snippets',
    stillProcessing: 'Still processing.. Pls check back soon!',
    // Buttons
    addUpdateGenresBtn: 'Add/Update Genres',
    changeBtn: 'Change',
    saveBtn: 'Save',
    cancelBtn: 'Cancel',
    cancelBooking: 'Cancel Booking',
    // Info: upcoming/past
    upcomingInfo: [
      'Please arrive at least 10 minutes early.',
      'Every guest gets 1 free drink/coffee.',
      'Complimentary hot water available.',
      'All merch 30% off!',
    ],
    // This text is now only shown at the bottom, after “素材反馈”, if incomplete
    incompleteInfo:
      'Please allow ~10 days for processing. If you want an upload, please contact staff or email us.',
    // Errors/Messages
    noBookingFound: 'Booking not found or not accessible.',
    loadingBooking: 'Loading booking detail...',
    areYouSureCancel: 'Are you sure you want to cancel this booking?',
    actionFinalConfirm: 'This action is final. Click OK to confirm.',
    bookingCanceled: 'Booking canceled successfully.',
    errorCancelBooking: 'Error canceling booking.',
    errorLoadBooking: 'Error fetching booking details.',
    errorGeneric: 'An error occurred.',
  },
};

const BookingDetail = () => {
  const { performanceId } = useParams();
  const navigate = useNavigate();
  const [language, setLanguage] = useState('zh');
  const t = translations[language];

  const [booking, setBooking] = useState(null);
  const [loading, setLoading] = useState(true);

  // Mixer editing
  const [mixerEditMode, setMixerEditMode] = useState(false);
  const [tempMixerModel, setTempMixerModel] = useState('');

  // Genre editing
  const [genreEditMode, setGenreEditMode] = useState(false);
  const [tempGenre1, setTempGenre1] = useState('');
  const [tempGenre2, setTempGenre2] = useState('');
  const [tempGenre3, setTempGenre3] = useState('');

  // Display fields
  const [mixerModel, setMixerModel] = useState('');
  const [genre1, setGenre1] = useState('');
  const [genre2, setGenre2] = useState('');
  const [genre3, setGenre3] = useState('');
  const [baiduLink, setBaiduLink] = useState('');
  const [ytLink, setYtLink] = useState('');

  // Additional fields for the once-only choice
  const [publishChoice, setPublishChoice] = useState(null);
  const [publishDate, setPublishDate] = useState(null);

  // Real-time flyer & snippets
  const [recFlyerBase64, setRecFlyerBase64] = useState(null);
  const [snippets, setSnippets] = useState([]);

  let isUpcoming = false;
  let isPast = false;

  // Language toggle
  const toggleLanguage = () => {
    setLanguage((prev) => (prev === 'zh' ? 'en' : 'zh'));
  };

  useEffect(() => {
    const fetchBookingDetail = async () => {
      setLoading(true);
      try {
        const resp = await axios.get('/api/booking/my_bookings/');
        const found = resp.data.find((b) => b.id.toString() === performanceId);
        if (!found) {
          toast.error(t.noBookingFound);
          navigate('/dj/booking');
          return;
        }
        setBooking(found);

        // Populate states
        setMixerModel(found.mixer_model || '');
        setGenre1(found.genre_1 || '');
        setGenre2(found.genre_2 || '');
        setGenre3(found.genre_3 || '');
        setBaiduLink(found.baidu_pan_link || '');
        setYtLink(found.yt_link || '');

        // If your model has "publish_video" & "publish_date"
        if (Object.hasOwn(found, 'publish_video')) {
          setPublishChoice(found.publish_video); // null, true, or false
        }
        if (found.publish_date) {
          const isoDate = parseISO(found.publish_date);
          setPublishDate(isoDate);
        }

        // Temp fields
        setTempMixerModel(found.mixer_model || '');
        setTempGenre1(found.genre_1 || '');
        setTempGenre2(found.genre_2 || '');
        setTempGenre3(found.genre_3 || '');

        // Snippets
        const snippetResp = await axios.get(`/api/booking/snippets/${performanceId}/`);
        setSnippets(snippetResp.data);

        // Real-time flyer
        const flyerResp = await axios.get(
          `/api/booking/get_rec_flyer_realtime/${performanceId}/`
        );
        if (flyerResp.data?.flyer_base64) {
          setRecFlyerBase64(flyerResp.data.flyer_base64);
        }
      } catch (error) {
        console.error(error);
        toast.error(t.errorLoadBooking);
        navigate('/dj/booking');
      } finally {
        setLoading(false);
      }
    };
    fetchBookingDetail();
  }, [performanceId, navigate, t]);

  if (loading) {
    return <p style={{ fontSize: '0.9rem' }}>{t.loadingBooking}</p>;
  }
  if (!booking) return null;

  // isPast => event_date < today
  const eventDateObj = parseISO(booking.event_date);
  const today = new Date();
  isUpcoming = isAfter(eventDateObj, today);
  isPast = isBefore(eventDateObj, today);

  // If user hasn't chosen and publish_date is in the past => default to upload
  if (publishChoice === null && publishDate && isAfter(new Date(), publishDate)) {
    // auto-choose upload
    axios
      .patch(`/api/booking/update/${booking.id}/`, { publish_video: true })
      .then(() => {
        setPublishChoice(true);
        toast.info(
          language === 'zh'
            ? `已过 ${formatDate(publishDate, 'yyyy-MM-dd')}，默认为上传`
            : `Publish date ${formatDate(publishDate, 'yyyy-MM-dd')} passed—defaulting to upload.`
        );
      })
      .catch((err) => {
        console.error('Error auto-uploading:', err);
        // no further handling needed
      });
  }

  // showUploadBox => if isPast, we have baiduLink, publishChoice===null, and today < publish_date
  let showUploadBox = false;
  if (isPast && baiduLink && publishChoice === null && publishDate) {
    if (isBefore(today, publishDate)) {
      showUploadBox = true;
    }
  }

  // canDelete => if event_date >= 10 days away
  const canDelete = isAfter(eventDateObj, addDays(today, 10));

  /** Cancel booking */
  const handleDeleteBooking = async () => {
    if (!window.confirm(t.areYouSureCancel)) return;
    if (!window.confirm(t.actionFinalConfirm)) return;
    try {
      await axios.post('/api/booking/cancel_booking/', {
        performance_id: booking.id,
      });
      toast.success(t.bookingCanceled);
      navigate('/dj/booking');
    } catch (err) {
      console.error('Error canceling booking:', err);
      toast.error(t.errorCancelBooking);
    }
  };

  /** Mixer Model Edit */
  const handleEditMixerModel = () => {
    setTempMixerModel(mixerModel);
    setMixerEditMode(true);
  };
  const handleSaveMixerModel = async () => {
    try {
      await axios.patch(`/api/booking/update/${booking.id}/`, {
        mixer_model: tempMixerModel.trim(),
      });
      toast.success('Mixer model updated successfully.');
      setMixerEditMode(false);
      setMixerModel(tempMixerModel.trim());
    } catch (error) {
      console.error(error);
      toast.error('Error updating mixer model.');
    }
  };
  const handleCancelMixerModel = () => {
    setMixerEditMode(false);
    setTempMixerModel(mixerModel);
  };

  /** Genres */
  const handleEditGenres = () => {
    setTempGenre1(genre1);
    setTempGenre2(genre2);
    setTempGenre3(genre3);
    setGenreEditMode(true);
  };

  const handleSaveGenres = async () => {
    try {
      await axios.patch(`/api/booking/update/${booking.id}/`, {
        genre_1: tempGenre1.trim(),
        genre_2: tempGenre2.trim(),
        genre_3: tempGenre3.trim(),
      });
      toast.success('Genres updated successfully.');
      setGenreEditMode(false);
      setGenre1(tempGenre1.trim());
      setGenre2(tempGenre2.trim());
      setGenre3(tempGenre3.trim());
    } catch (error) {
      console.error(error);
      toast.error('Error updating genres.');
    }
  };
  const handleCancelGenres = () => {
    setGenreEditMode(false);
    setTempGenre1(genre1);
    setTempGenre2(genre2);
    setTempGenre3(genre3);
  };

  /** Format 24h => 12h */
  const format12Hour = (timeStr) => {
    if (!timeStr) return '';
    const [hours, minutes] = timeStr.split(':');
    let hh = parseInt(hours, 10);
    let suffix = hh >= 12 ? 'pm' : 'am';
    if (hh === 0) {
      hh = 12;
    } else if (hh > 12) {
      hh -= 12;
    }
    let mm = minutes || '00';
    if (mm === '00') {
      return `${hh}${suffix}`;
    }
    return `${hh}:${mm}${suffix}`;
  };

  /** Once-only upload choice => store in "publish_video" */
  const handlePublishChoice = async (choice) => {
    try {
      await axios.patch(`/api/booking/update/${booking.id}/`, {
        publish_video: choice, // new field your UpdateBookingView handles
      });
      setPublishChoice(choice);
      toast.success(choice ? t.uploadYes : t.uploadNo);
    } catch (err) {
      console.error('Error saving publish choice:', err);
      toast.error(t.errorGeneric);
    }
  };

  /** snippet download */
  const handleSnippetDownload = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  // We'll show the incomplete info at the bottom if any are missing
  const anyMissing = !baiduLink || !ytLink || snippets.length === 0;

  // Insert the actual publish_date in the uploadBoxDesc text
  let uploadDesc = t.uploadBoxDesc;
  if (publishDate) {
    const dateStr = formatDate(publishDate, 'yyyy-MM-dd');
    uploadDesc = uploadDesc.replace('{publishDate}', dateStr);
  }

  return (
    <div className="booking-detail-container" style={{ fontSize: '0.9rem' }}>
      {/* Language toggle */}
      <button className="translate-button" onClick={toggleLanguage}>
        {language === 'zh' ? 'EN' : '中'}
      </button>

      {/* Back arrow */}
      <IconButton className="back-arrow" onClick={() => navigate(-1)}>
        <ArrowBackIcon />
      </IconButton>

      {/* Title */}
      <h2 className="booking-detail-title">{t.pageTitle}</h2>

      {/* Upload Option Box => only if showUploadBox */}
      {showUploadBox && (
        <div
          style={{
            backgroundColor: '#ccffcc',
            padding: '1rem',
            marginBottom: '1rem',
            marginTop: '1.5rem',
            border: '1px solid #000',
          }}
        >
          <h3 style={{ marginTop: 0, marginBottom: '0.5rem', fontSize: '1rem' }}>
            {t.uploadBoxTitle}
          </h3>
          <p style={{ marginBottom: '0.8rem', fontSize: '0.85rem' }}>
            {uploadDesc}
          </p>
          <div style={{ display: 'flex', gap: '1rem' }}>
            <button
              style={{ fontSize: '0.85rem' }}
              onClick={() => handlePublishChoice(true)}
            >
              {t.uploadYes}
            </button>
            <button
              style={{ fontSize: '0.85rem' }}
              onClick={() => handlePublishChoice(false)}
            >
              {t.uploadNo}
            </button>
          </div>
        </div>
      )}

      {/* SECTION 1: 电台做客信息 */}
      <h2 style={{ margin: '0 0 10px 0', textAlign: 'center', fontSize: '1rem' }}>
        {t.section1Title}
      </h2>
      <div className="booking-info-wrapper">
        <div className="booking-info">
          {/* Event Name */}
          <div className="row-field">
            <div className="label">{t.eventNameLabel}:</div>
            <div className="value">{booking.event_name || 'N/A'}</div>
          </div>

          {/* DJ Name */}
          <div className="row-field">
            <div className="label">{t.djNameLabel}:</div>
            <div className="value">{booking.dj_name || ''}</div>
          </div>

          {/* Date */}
          <div className="row-field">
            <div className="label">{t.dateLabel}:</div>
            <div className="value">{formatDate(parseISO(booking.event_date), 'yyyy-MM-dd')}</div>
          </div>

          {/* Time => 12-hr */}
          <div className="row-field">
            <div className="label">{t.timeLabel}:</div>
            <div className="value">{format12Hour(booking.time)}</div>
          </div>

          {/* Location if any */}
          {booking.location_addr && (
            <div className="row-field">
              <div className="label">{t.locationLabel}:</div>
              <div className="value">{booking.location_addr}</div>
            </div>
          )}

          {/* Mixer => no edit if past */}
          <div className="row-field">
            <div className="label">{t.mixerModelLabel}:</div>
            <div className="value edit-row">
              {isPast ? (
                <span>{mixerModel}</span>
              ) : mixerEditMode ? (
                <>
                  <select
                    value={tempMixerModel}
                    onChange={(e) => setTempMixerModel(e.target.value)}
                  >
                    <option value="DJM-900 NXS2">DJM-900 NXS2</option>
                    <option value="XONE 96">XONE 96</option>
                  </select>
                  <button className="save-button" onClick={handleSaveMixerModel}>
                    {t.saveBtn}
                  </button>
                  <button className="cancel-button" onClick={handleCancelMixerModel}>
                    {t.cancelBtn}
                  </button>
                </>
              ) : (
                <>
                  <span>{mixerModel}</span>
                  {isAfter(parseISO(booking.event_date), new Date()) && (
                    <button className="edit-button" onClick={handleEditMixerModel}>
                      {t.changeBtn}
                    </button>
                  )}
                </>
              )}
            </div>
          </div>

          {/* Genres => if isPast => no edit button */}
          <div className="row-field">
            <div className="label">{t.genresLabel}:</div>
            <div className="value edit-row">
              {genreEditMode ? (
                <>
                  <div className="genres-input">
                    <input
                      type="text"
                      placeholder="Genre 1"
                      value={tempGenre1}
                      onChange={(e) => setTempGenre1(e.target.value)}
                      style={{ width: '70px' }}
                    />
                    <input
                      type="text"
                      placeholder="Genre 2"
                      value={tempGenre2}
                      onChange={(e) => setTempGenre2(e.target.value)}
                      style={{ width: '70px' }}
                    />
                    <input
                      type="text"
                      placeholder="Genre 3"
                      value={tempGenre3}
                      onChange={(e) => setTempGenre3(e.target.value)}
                      style={{ width: '70px' }}
                    />
                  </div>
                  <button className="save-button" onClick={handleSaveGenres}>
                    {t.saveBtn}
                  </button>
                  <button className="cancel-button" onClick={handleCancelGenres}>
                    {t.cancelBtn}
                  </button>
                </>
              ) : (
                <>
                  <span>
                    {[genre1, genre2, genre3].filter(Boolean).join(', ') ||
                      t.noGenres}
                  </span>
                  {/* {!isPast && ( */}
                    <button className="edit-button" onClick={handleEditGenres}>
                      {t.addUpdateGenresBtn}
                    </button>
                  {/* )} */}
                </>
              )}
            </div>
          </div>

          {/* Info box for upcoming/past */}
          {isUpcoming && (
            <div className="info-box">
              {t.upcomingInfo.map((line, idx) => (
                <p key={idx}>{line}</p>
              ))}
            </div>
          )}

          {/* Cancel booking if canDelete */}
          {isAfter(eventDateObj, addDays(new Date(), 10)) && (
            <div className="detail-buttons">
              <button onClick={handleDeleteBooking} className="delete-button">
                {t.cancelBooking}
              </button>
            </div>
          )}
        </div>
      </div>

      {/* SECTION 2: 宣发物料 */}
      <div className="promotional-section">
        <h2 style={{ fontSize: '1rem', textAlign: 'center' }}>
          {t.section2Title}
        </h2>
        <div className="promo-box">
          {booking.square_thumbnail_url && (
            <div className="thumbnail-section align-top">
              <img
                src={booking.square_thumbnail_url}
                alt="Square Thumbnail"
                onClick={() => window.open(booking.square_thumbnail_url, '_blank')}
              />
            </div>
          )}
          {recFlyerBase64 && (
            <div className="flyer-section align-top">
              <img
                src={`data:image/jpeg;base64,${recFlyerBase64}`}
                alt="Rec Flyer"
              />
            </div>
          )}
        </div>
      </div>

      {/* SECTION 3: 素材反馈 */}
      <div className="postevent-section">
        <h2 style={{ fontSize: '1rem', textAlign: 'center' }}>
          {t.section3Title}
        </h2>
        <div className="postevent-box">
          {/* Baidu Link */}
          <div className="row-field">
            <div className="label">{t.baiduLinkLabel}:</div>
            <div className="value">
              {baiduLink ? (
                <a href={baiduLink} target="_blank" rel="noopener noreferrer">
                  {baiduLink}
                </a>
              ) : (
                <span>{t.stillProcessing}</span>
              )}
            </div>
          </div>
          {/* YouTube Link => if no ytLink => "stillProcessing" */}
          <div className="row-field">
            <div className="label">{t.youtubeLinkLabel}:</div>
            <div className="value">
              {ytLink ? (
                <a
                  href={`https://www.youtube.com/watch?v=${ytLink}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.youtube.com/watch?v={ytLink}
                </a>
              ) : (
                <span>{t.stillProcessing}</span>
              )}
            </div>
          </div>
          {/* Highlight snippets => if empty => still processing */}
          <div className="row-field">
            <div className="label">{t.highlightSnippetsLabel}:</div>
            <div className="value">
              {snippets.length > 0 ? (
                <div className="highlight-snippets">
                  <div className="snippet-row">
                    {snippets.map((snip) => (
                      <div key={snip.id} className="snippet-item">
                        <video src={snip.snippet_url} controls></video>
                        <button
                          className="snippet-download-btn"
                          onClick={() => handleSnippetDownload(snip.snippet_url)}
                        >
                          {language === 'zh' ? '下载' : 'Download'}
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <span>{t.stillProcessing}</span>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* If some items are missing => show incomplete info at bottom */}
      {(() => {
        const missingBaidu = !baiduLink;
        const missingYT = !ytLink;
        const missingSnippets = snippets.length === 0;
        if (missingBaidu || missingYT || missingSnippets) {
          return (
            <div className="info-box" style={{ marginTop: '1rem', backgroundColor: '#ccffcc' }}>
              <p>{t.incompleteInfo}</p>
            </div>
          );
        }
        return null;
      })()}
    </div>
  );
};

export default BookingDetail;
