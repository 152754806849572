// src/ContactUs.js
import React, { useState } from 'react';

const ContactUs = () => {
  // Language state: 'en' or 'zh'
  const [language, setLanguage] = useState('en');

  // Toggle between English and Chinese
  const toggleLanguage = () => {
    setLanguage((prev) => (prev === 'en' ? 'zh' : 'en'));
  };

  // Translations object
  const translations = {
    en: {
      heading: 'Contact Us',
      line1:
        "If you have questions, collaboration opportunities, application questions, or need more information about our radio programs, don't hesitate to reach out! We are passionate about connecting with our community, the culture and of course, very good music 🪴",
      line2: (
        <>
          Feel free to email us at: <strong>byyb.radio@gmail.com</strong> or our personal WeChat:{' '}
          <strong>byyb_radio</strong> for more inquiries.
        </>
      ),
      line3: 'We are a community radio located in the heart of Shanghai, China 🇨🇳',
      line4: '💚',
      line5: '📍 115 Changle Rd, Shanghai, China (上海市黄浦区长乐路115号)',
      socialsTitle: 'Follow us on our socials:',
      links: {
        instagram: 'instagram: byyb.radio',
        youtube: 'youtube: byyb_radio',
        wechat: 'wechat: ',
        xiaohongshu: 'xiaohongshu: byyb',
        bilibili: 'bilibili: byyb_radio',
      },
    },
    zh: {
      heading: '联系我们',
      line1:
        '如果有你想了解更多关于电台节目的信息，创意联动，商业合作，艺术交流，欢迎随时联系我们！我们是一个热衷于与社区、文化和好音乐的团队，希望能和你建立联系 🪴',
      line2: (
        <>
          邮件请发送到：<strong>byyb.radio@gmail.com</strong> 或者可以添加个人微信：
          <strong>byyb_radio</strong>。
        </>
      ),
      line3: '我们是一家位于中国上海市中心街边的社区电台 🇨🇳',
      line4: '💚',
      line5: '📍 上海市黄浦区长乐路115号（金叶通讯旁）',
      socialsTitle: '社交媒体请关注：',
      links: {
        instagram: 'instagram: byyb.radio',
        youtube: 'youtube: byyb_radio',
        wechat: 'wechat: ',
        xiaohongshu: '小红书: byyb',
        bilibili: 'bilibili: byyb_radio',
      },
    },
  };

  // Helper function to render "label: value" with the value in green/underlined
  const renderSocialLink = (text) => {
    // Each link string is in the format "<label>: <value>"
    // We'll split on the first ": "
    const parts = text.split(': ');
    if (parts.length === 2) {
      const label = parts[0] + ': ';
      const value = parts[1];
      return (
        <>
          {label}
          <span style={{ color: 'green', textDecoration: 'underline' }}>
            {value}
          </span>
        </>
      );
    }
    // Fallback: if format is unexpected, just return text
    return text;
  };

  const t = translations[language];

  return (
    <div
      style={{
        maxWidth: '600px',
        margin: '80px auto 20px',
        padding: '20px',
        backgroundColor: '#fff',
        border: '1px solid #000',
        position: 'relative',
      }}
    >
      {/* Language toggle button */}
      <button
        onClick={toggleLanguage}
        style={{
          position: 'absolute',
          top: 10,
          right: 10,
          border: '1px solid #000',
          backgroundColor: '#fff',
          cursor: 'pointer',
          padding: '5px 10px',
        }}
      >
        {language === 'en' ? '中' : 'EN'}
      </button>

      <h2 style={{ textAlign: 'center' }}>{t.heading}</h2>

      <p style={{ fontSize: '0.9rem', lineHeight: '1.5', padding: '10px 0' }}>
        {t.line1}
      </p>
      <p style={{ fontSize: '0.9rem', lineHeight: '1.5' }}>{t.line2}</p>
      <p style={{ fontSize: '0.9rem', lineHeight: '1.5' }}>{t.line3}</p>
      <p style={{ fontSize: '0.9rem', lineHeight: '1.5' }}>{t.line4}</p>

      <p style={{ fontSize: '0.9rem', lineHeight: '1.5', margin: '15px 0 5px' }}>
        {t.socialsTitle}
      </p>
      <ul
        style={{
          fontSize: '0.8rem',
          lineHeight: '1.5',
          listStyleType: 'none',
          padding: 0,
        }}
      >
        <li>
          <a
            href={
              language === 'en'
                ? 'https://www.instagram.com/byyb.radio'
                : 'https://www.instagram.com/byyb.radio'
            }
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none', color: 'black' }}
          >
            {renderSocialLink(t.links.instagram)}
          </a>
        </li>
        <li>
          <a
            href="https://www.youtube.com/@byyb_radio"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none', color: 'black' }}
          >
            {renderSocialLink(t.links.youtube)}
          </a>
        </li>
        <li>
          <a
            href="https://weixin.qq.com/r/byyb"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none', color: 'black' }}
          >
            {renderSocialLink(t.links.wechat)}
            byyb_radio
          </a>
        </li>
        <li>
          <a
            href="https://www.xiaohongshu.com/user/profile/63565b35000000001901c0dd"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none', color: 'black' }}
          >
            {renderSocialLink(t.links.xiaohongshu)}
          </a>
        </li>
        <li>
          <a
            href="https://space.bilibili.com/330497744"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none', color: 'black' }}
          >
            {renderSocialLink(t.links.bilibili)}
          </a>
        </li>
      </ul>

      {/* line5 is the address in each language */}
      <p style={{ fontSize: '0.8rem', lineHeight: '3.5' }}>{t.line5}</p>
    </div>
  );
};

export default ContactUs;
