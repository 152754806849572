// src/bookings/NewBooking.js

import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { enUS } from 'date-fns/locale';
import { toast } from 'react-toastify';
import { format, isBefore } from 'date-fns';
import './NewBooking.css';
import { AuthContext } from '../AuthContext';
import { useNavigate } from 'react-router-dom';

/** MUI imports */
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';

registerLocale('en-US', enUS);

const NewBooking = () => {
  const { username, hasDjProfile, loading } = useContext(AuthContext);
  const navigate = useNavigate();

  // Step state
  const [step, setStep] = useState(1);

  // Calendar data (Step 3)
  const [availableDates, setAvailableDates] = useState([]);
  const [userBookedDates, setUserBookedDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [loadingDates, setLoadingDates] = useState(true);

  // Timeslots
  const [performances, setPerformances] = useState([]);
  const [loadingTimeslots, setLoadingTimeslots] = useState(false);

  // Event details for the selected date
  const [eventDetails, setEventDetails] = useState(null);

  // Selected timeslot
  const [selectedPerformance, setSelectedPerformance] = useState(null);

  // Performance type
  const [isLive, setIsLive] = useState(null);
  const [mixerModel, setMixerModel] = useState('DJM-900 NXS2');

  // Booking eligibility
  const [canBook, setCanBook] = useState(true);
  const [bookingMessage, setBookingMessage] = useState('');
  const [isBooking, setIsBooking] = useState(false);

  // Loading overlay
  const [isLoading, setIsLoading] = useState(false);

  // Next/Previous button loading
  const [isNextLoading, setIsNextLoading] = useState(false);
  const [isPreviousLoading, setIsPreviousLoading] = useState(false);

  // DJ profile
  const [djProfile, setDjProfile] = useState(null);

  // Language - default is Chinese
  const [language, setLanguage] = useState('zh');

  // Programs data
  const programs = {
    ia: {
      name: 'ivy arum session 🪴',
      intro:
        "ivy arum session (绿萝时间) is a program that originated from the byyb.radio station..."
    },
    rs: {
      name: 'byyb.radio Main Program 💚',
      intro:
        "Since October 2023, the main program has been running for nearly 40 weeks..."
    },
    hr: {
      name: 'Heimliday Radio 🌳',
      intro:
        "The Heimliday Radio program, jointly hosted by Heim club and byyb.radio in Shanghai..."
    },
    bo: {
      name: 'byyb.radio Out 💨',
      intro:
        "Whether it's at the entrance of a street alley, a busy mall, the heart of a club..."
    },
  };

  // Translations (updated Step 1, Step 2, and success messages)
  const translations = {
    zh: {
      step1: {
        header: '电台预约系统',
        content:
          '欢迎来到电台嘉宾系统！你可以在这里预约电台做客 🪴\n\n' +
          '电台会不定期开设新的日期，包括每周的个人电台，和一些在特殊场地的电台活动，包括一些商业演出活动，欢迎大家密切关注和预约！',
        next: '下一步',
      },
      step2: {
        header: '电台预约及线下做客注意事项',
        content: [
          // 1) 电台预约
          '电台预约：',
          '1. 为能保证有限的电台档期均匀分布，一位嘉宾每两次做客需要相隔4个月。',
          '2. 对于已预约好的电台做客，如有特殊紧急情况需取消/修改，请联系byyb.radio@gmail.com',
          '3. 相关的电台做客信息/素材会在“我的预约”里展示。',
          '4. 电台反馈的音频/视频一般会在一周左右准备好，并可以在“我的预约”里下载，可以在“我的预约”里选择上传偏好。',
          '5. 因为做客数量有限，若预约者缺席且没有通知，我们会关闭其网上的预约功能，请谅解。',
          '',
          // 2) 线下做客
          '线下做客：',
          '1. 电台做客均为线下活动，需要嘉宾来电台/特殊场景，目前不支持远程素材投稿。',
          '2. 每位嘉宾我们可免费选店里的一款饮料/酒。',
          '3. 如碰到恶劣天气或其他不可控因素，我们会提前来跟你沟通修改日期。',
          '4. 上海长乐路115号,No.115 Changle Rd,Shanghai (特殊/商业活动除外，具体请查阅具体的信息）',
        ],
        previous: '上一步',
        next: '下一步',
      },
      step3: {
        header: '选择日期',
        timeslotHeader: '选择做客时间 🕝',
        previous: '上一步',
        next: '下一步',
      },
      step4: {
        header: '选择演出类型',
        djSet: 'DJ Set 🎛️',
        liveSet: 'Live Set 🎺',
        mixerModelLabel: '首选混音台型号：',
        previous: '上一步',
        next: '下一步',
        errorSelectPerformanceType: '请选择您的演出类型。',
      },
      step5: {
        header: '确认预订详情',
        date: '日期',
        time: '时间',
        djName: '嘉宾',
        performanceType: '演出类型',
        mixerModel: '混音台型号',
        liveSet: 'Live Set',
        djSet: 'DJ Set',
        previous: '上一步',
        confirm: '确认预订 🪴',
      },
      loadingDates: '加载可用日期...',
      loadingTimeslots: '加载时间段...',
      noTimeslots: '该日期暂无可用时间段。',
      booking: {
        bookingError: '请先选择一个时间段进行预约。',
        bookingErrorMessage:
          '您无法预约新的演出，因为您在最近3个月内有过预约。',
        // Updated success message (includes placeholders for date/time/location)
        bookingSuccess:
          `<div>恭喜！电台档期预约成功！<br /><br />
          我们【{date} {time}】在【{location}】见！<br />
          欢迎带上朋友一同前来，一起来感受平时听不到的声音。因为每个小时都是一个惊喜。<br /><br />
          我们也会提供任意一款免费的饮品，直接和工作人员说。<br /><br />
          做客的素材可在 &lt;link to the BookingDetail&gt; 中下载！<br /><br />
          有其他的疑问，欢迎联系我们: byyb.radio@gmail.com<br /><br />
          byyb.team</div>`,
        bookingLimit: '您已达到预约限制。',
        bookingGenericError: '预约时出错。',
      },
    },
    en: {
      step1: {
        header: 'Radio Booking System',
        content:
          'Welcome to the Radio Guest System! You can schedule your radio session here 🪴\n\n' +
          'The radio station will open new dates from time to time, including weekly personal radio sessions and some events at special venues (including commercial performances). Feel free to keep an eye on them and book whenever you like!',
        next: 'Next',
      },
      step2: {
        header: 'Radio Booking & On-Site Session Info',
        content: [
          'Radio Booking:',
          '1. To ensure limited time slots are distributed fairly, each guest must wait at least 4 months between any two sessions.',
          '2. If you need to cancel/modify a booked session due to emergencies, please contact byyb.radio@gmail.com',
          '3. Your radio session info/materials will appear under "My Bookings".',
          '4. Audio/video from the session is typically ready in about a week and can be downloaded under "My Bookings". You can also set your upload preferences there.',
          '5. Because the number of sessions is limited, if a guest is absent without notice, we may disable their online booking access—thanks for understanding.',
          '',
          'On-Site Session:',
          '1. All radio appearances are on-site events, requiring guests to come in-person; remote submissions are not supported at this time.',
          '2. Each guest can enjoy one free drink (coffee/beer) from our selection.',
          '3. If there is bad weather or other uncontrollable factors, we will contact you in advance to reschedule.',
          '4. 115 Changle Rd, Shanghai (No.115 Changle Rd, Shanghai) — for special/commercial events, please refer to specific instructions.',
        ],
        previous: 'Previous',
        next: 'Next',
      },
      step3: {
        header: 'Choose Date',
        timeslotHeader: 'Pick a Time Slot!',
        previous: 'Previous',
        next: 'Next',
      },
      step4: {
        header: 'Select Performance Type',
        djSet: 'DJ Set',
        liveSet: 'Live Set',
        mixerModelLabel: 'Preferred Mixer Model:',
        previous: 'Previous',
        next: 'Next',
        errorSelectPerformanceType: 'Please select your performance type.',
      },
      step5: {
        header: 'Confirm Booking Details',
        date: 'Date',
        time: 'Time',
        djName: 'Guest',
        performanceType: 'Performance Type',
        mixerModel: 'Mixer Model',
        liveSet: 'Live Set 🎺',
        djSet: 'DJ Set 🎛️',
        previous: 'Previous',
        confirm: 'Confirm Booking 🪴',
      },
      loadingDates: 'Loading available dates...',
      loadingTimeslots: 'Loading timeslots...',
      noTimeslots: 'No timeslots available for this date.',
      booking: {
        bookingError: 'Select a timeslot to book 🕝',
        bookingErrorMessage:
          'You cannot book a new performance because you have a recent booking.',
        // Updated success message (includes placeholders for date/time/location)
        bookingSuccess:
          `<div>Congratulations! Your radio session is booked!<br /><br />
          We'll see you on {date} at {time} at {location}.<br />
          Feel free to bring friends along to experience sounds you normally don't hear—every hour is a surprise.<br /><br />
          We'll also provide one free drink of your choice—just let the staff know.<br /><br />
          You can download your session materials at &lt;link to the BookingDetail&gt;.<br /><br />
          If you have any questions, please contact us at byyb.radio@gmail.com<br /><br />
          byyb.team</div>`,
        bookingLimit: 'You have reached your booking limit.',
        bookingGenericError: 'Error booking performance.',
      },
    },
  };

  // Steps array
  const steps = [
    translations[language].step1.header,
    translations[language].step2.header,
    translations[language].step3.header,
    translations[language].step4.header,
  ];

  // Must have DJ profile
  useEffect(() => {
    if (!loading && !hasDjProfile) {
      navigate('/dj/profile', { state: { fromBooking: true } });
    }
  }, [loading, hasDjProfile, navigate]);

  // Fetch DJ profile
  useEffect(() => {
    if (!hasDjProfile) return;
    axios
      .get('/api/dj/profile/')
      .then((resp) => setDjProfile(resp.data))
      .catch((err) => console.error('Error fetching DJ profile:', err));
  }, [hasDjProfile]);

  // Step 3 => fetch available dates
  useEffect(() => {
    const fetchDates = async () => {
      setLoadingDates(true);
      try {
        const resp = await axios.get('/api/booking/available_dates/');
        setAvailableDates(resp.data.available_dates);
        setUserBookedDates(resp.data.user_booked_dates);
      } catch (error) {
        console.error('Error fetching available dates:', error);
        toast.error('Error fetching available dates.');
      } finally {
        setLoadingDates(false);
      }
    };
    fetchDates();
  }, []);

  // Toggle language (default is zh -> switch to en, else back to zh)
  const toggleLanguage = () => {
    setLanguage((prev) => (prev === 'zh' ? 'en' : 'zh'));
  };

  // Next
  const handleNext = () => {
    if (step === 4 && isLive === null) {
      toast.error(
        language === 'zh'
          ? translations.zh.step4.errorSelectPerformanceType
          : translations.en.step4.errorSelectPerformanceType
      );
      return;
    }
    if (step < steps.length + 1) {
      setIsNextLoading(true);
      setIsLoading(true);
      setTimeout(() => {
        setStep((prev) => prev + 1);
        setIsNextLoading(false);
        setIsLoading(false);
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }, 500);
    }
  };

  // Previous
  const handlePrevious = () => {
    if (step > 1) {
      setIsPreviousLoading(true);
      setIsLoading(true);
      setTimeout(() => {
        setStep((prev) => prev - 1);
        setIsPreviousLoading(false);
        setIsLoading(false);
      }, 500);
    }
  };

  // Step 3 => user picks date => fetch timeslots
  const handleDateChange = async (date) => {
    setSelectedDate(date);
    setSelectedPerformance(null);

    if (!date) return;
    setLoadingTimeslots(true);
    try {
      const dateStr = format(date, 'yyyy-MM-dd');
      const resp = await axios.get('/api/booking/timeslots/', {
        params: { date: dateStr },
      });
      setPerformances(resp.data);

      if (resp.data.length > 0) {
        const firstPerf = resp.data[0];
        // If event_color is #ffffff => keep #28a745, else event_color
        let finalColor = firstPerf.event_color || '#ffffff';
        if (finalColor === '#ffffff') {
          finalColor = '#28a745';
        }
        setEventDetails({
          week_no: firstPerf.event_week_no,
          event_name: firstPerf.event_name,
          date: firstPerf.event_date,
          program: firstPerf.event_program,
          location: firstPerf.location,
          partner: firstPerf.partner,
          label: firstPerf.label,
          event_color: finalColor,
        });
      } else {
        setEventDetails(null);
      }
    } catch (error) {
      console.error('Error fetching timeslots:', error);
      toast.error('Error fetching timeslots.');
    } finally {
      setLoadingTimeslots(false);
    }
  };

  // dayClassName for the DatePicker
  const dayClassName = (date) => {
    const dateStr = format(date, 'yyyy-MM-dd');
    // If date is in the past
    if (isBefore(date, new Date())) {
      return 'react-datepicker__day--disabled';
    }
    // If not an event => grey out
    if (!availableDates.includes(dateStr)) {
      return 'react-datepicker__day--unavailable';
    }
    // If user has booked => user-booked
    if (userBookedDates.includes(dateStr)) {
      return 'react-datepicker__day--user-booked';
    }
    return '';
  };

  // For customizing day color
  const renderDayContents = (dayOfMonth, date) => {
    const dateStr = format(date, 'yyyy-MM-dd');

    // If not an event => grey
    if (!availableDates.includes(dateStr)) {
      return <span style={{ color: '#aaa' }}>{dayOfMonth}</span>;
    }

    // If user booked => orange
    if (userBookedDates.includes(dateStr)) {
      return (
        <span
          style={{
            backgroundColor: 'orange',
            color: '#fff',
            display: 'inline-block',
            width: '2rem',
            height: '2rem',
            lineHeight: '2rem',
            textAlign: 'center',
            borderRadius: 0,
          }}
        >
          {dayOfMonth}
        </span>
      );
    }

    // Default => green
    return (
      <span
        style={{
          backgroundColor: '#28a745',
          color: '#fff',
          display: 'inline-block',
          width: '2rem',
          height: '2rem',
          lineHeight: '2rem',
          textAlign: 'center',
          borderRadius: 0,
        }}
      >
        {dayOfMonth}
      </span>
    );
  };

  // Step 3 => pick performance slot
  const handlePerformanceSelection = async (perf) => {
    // If someone else booked it
    const isBookedByAnother =
      perf.dj.length > 0 &&
      !perf.dj.some((djObj) => djObj.username === username);
    if (isBookedByAnother) return;

    setSelectedPerformance(perf);

    // Check can_book
    if (perf.event_id && selectedDate) {
      try {
        const canResp = await axios.get('/api/booking/can_book/', {
          params: {
            event_id: perf.event_id,
            date: format(selectedDate, 'yyyy-MM-dd'),
          },
        });
        setCanBook(canResp.data.can_book);
        setBookingMessage(canResp.data.message || '');
      } catch (err) {
        console.error('Error checking booking eligibility:', err);
        setCanBook(false);
        setBookingMessage('Error checking booking eligibility.');
      }
    }
  };

  // Step 5 => confirm booking
  const handleBookPerformance = async () => {
    if (!selectedPerformance) {
      toast.error(
        language === 'zh'
          ? translations.zh.booking.bookingError
          : translations.en.booking.bookingError
      );
      return;
    }
    if (!canBook) {
      toast.error(
        language === 'zh'
          ? translations.zh.booking.bookingErrorMessage
          : translations.en.booking.bookingErrorMessage
      );
      return;
    }
    setIsLoading(true);
    setIsBooking(true);
    try {
      const data = {
        event_id: selectedPerformance.event_id,
        time: selectedPerformance.time,
        is_live: isLive,
      };
      if (isLive === false) {
        data.mixer_model = mixerModel;
      }
      await axios.post('/api/booking/book/', data);

      // Construct success message with placeholders:
      const successMsgRaw =
        language === 'zh'
          ? translations.zh.booking.bookingSuccess
          : translations.en.booking.bookingSuccess;

      const successMsg = successMsgRaw
        .replace('{date}', format(selectedDate, 'yyyy-MM-dd'))
        .replace('{time}', selectedPerformance.time.slice(0, 5))
        .replace(
          '{location}',
          eventDetails?.location
            ?.map((loc) => loc.location_addr)
            .join(', ') || ''
        );

      // Use a toast with HTML for multiline
      toast.success(<div dangerouslySetInnerHTML={{ __html: successMsg }} />, {
        autoClose: 10000,
      });

      // Redirect to MyBookings after success
      navigate('/dj/booking');
    } catch (err) {
      console.error('Error booking performance:', err);
      if (err.response && err.response.data && err.response.data.detail) {
        toast.error(err.response.data.detail);
      } else {
        toast.error(
          language === 'zh'
            ? translations.zh.booking.bookingGenericError
            : translations.en.booking.bookingGenericError
        );
      }
    } finally {
      setIsLoading(false);
      setIsBooking(false);
    }
  };

  return (
    <div className="new-booking-container">
      {/* Language toggle top-right */}
      <button
        className="translate-button"
        style={{ position: 'absolute', top: 10, right: 10, zIndex: 999 }}
        onClick={toggleLanguage}
      >
        {language === 'zh' ? 'EN' : '中'}
      </button>

      {/* Back arrow top-left */}
      <IconButton
        style={{ position: 'absolute', top: 10, left: 10, zIndex: 999 }}
        onClick={() => navigate(-1)}
      >
        <ArrowBackIcon />
      </IconButton>

      <div className={`new-booking-content ${isLoading ? 'blur' : ''}`}>
        {/* Steps */}
        <div className="new-progress-indicator">
          {steps.map((label, idx) => (
            <div
              key={idx}
              className={`new-step ${
                step === idx + 1 ? 'active' : step > idx + 1 ? 'completed' : ''
              }`}
            >
              <div className="new-step-number">
                {step > idx + 1 ? '✔' : idx + 1}
              </div>
              <div className="new-step-label">{label}</div>
            </div>
          ))}
        </div>

        {/* STEP 1 */}
        {step === 1 && (
          <>
            <h3>{translations[language].step1.header}</h3>
            {/* Allow line breaks for step 1 content */}
            {translations[language].step1.content.split('\n').map((text, i) => (
              <p key={i}>{text}</p>
            ))}

            <div className="navigation-buttons">
              <button
                className="new-next-button"
                style={{ border: '2px solid black' }}
                onClick={handleNext}
                disabled={isNextLoading}
              >
                {isNextLoading ? (
                  <span className="spinner"></span>
                ) : (
                  translations[language].step1.next
                )}
              </button>
            </div>
          </>
        )}

        {/* STEP 2 */}
        {step === 2 && (
          <>
            <h3>{translations[language].step2.header}</h3>
            <ul className="new-booking-description">
              {translations[language].step2.content.map((item, i) =>
                item ? <li key={i}>{item}</li> : <br key={i} />
              )}
            </ul>
            <div className="navigation-buttons">
              <button
                className="new-previous-button"
                style={{ border: '2px solid black' }}
                onClick={handlePrevious}
                disabled={isPreviousLoading}
              >
                {isPreviousLoading ? (
                  <span className="spinner"></span>
                ) : (
                  translations[language].step2.previous
                )}
              </button>
              <button
                className="new-next-button"
                style={{ border: '2px solid black' }}
                onClick={handleNext}
                disabled={isNextLoading}
              >
                {isNextLoading ? (
                  <span className="spinner"></span>
                ) : (
                  translations[language].step2.next
                )}
              </button>
            </div>
          </>
        )}

        {/* STEP 3 */}
        {step === 3 && (
          <>
            <h3>{translations[language].step3.header}</h3>
            {loadingDates ? (
              <p>{translations[language].loadingDates}</p>
            ) : (
              <div style={{ marginBottom: '20px' }}>
                <DatePicker
                  selected={selectedDate}
                  onChange={handleDateChange}
                  dayClassName={dayClassName}
                  renderDayContents={renderDayContents}
                  inline
                  locale="en-US"
                />
              </div>
            )}

            {selectedDate && (
              <div className="new-timeslots-container section-spacing">
                {eventDetails && (
                  <div
                    className="event-details-box"
                    style={{
                      fontSize: '0.85rem',
                      backgroundColor: eventDetails.event_color || '#28a745',
                    }}
                  >
                    <h3>{eventDetails.event_name}</h3>
                    <p>
                      <strong>
                        {language === 'zh' ? '日期' : translations.en.step5.date}:
                      </strong>{' '}
                      {eventDetails.date}
                    </p>

                    {programs[eventDetails.program] && (
                      <Accordion
                        variant="outlined"
                        square
                        style={{
                          marginTop: 5,
                          background: 'transparent',
                          border: '1px solid #aaaaaa'
                        }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          style={{ minHeight: '20px', margin: '0px' }}
                        >
                          <strong>{programs[eventDetails.program].name}</strong>
                        </AccordionSummary>
                        <AccordionDetails>
                          <p>{programs[eventDetails.program].intro}</p>
                        </AccordionDetails>
                      </Accordion>
                    )}

                    {eventDetails.label && eventDetails.label.length > 0 && (
                      <div style={{ marginTop: '5px' }}>
                        {eventDetails.label.map((lb) => (
                          <Accordion
                            variant="outlined"
                            square
                            style={{
                              marginTop: 5,
                              background: 'transparent',
                              border: '1px solid #aaaaaa',
                              margin: '0px'
                            }}
                            key={lb.id}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              style={{ minHeight: '20px' }}
                            >
                              <strong>Label: {lb.label_name}</strong>
                            </AccordionSummary>
                            <AccordionDetails>
                              <p>{lb.label_desc || '(No description)'}</p>
                            </AccordionDetails>
                          </Accordion>
                        ))}
                      </div>
                    )}

                    {eventDetails.partner && eventDetails.partner.length > 0 && (
                      <div style={{ marginTop: '5px' }}>
                        {eventDetails.partner.map((pt) => (
                          <Accordion
                            variant="outlined"
                            square
                            style={{
                              marginTop: 5,
                              background: 'transparent',
                              border: '1px solid #aaaaaa',
                              marginBottom: '20px'
                            }}
                            key={pt.id}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              style={{ minHeight: '20px', margin: '0px' }}
                            >
                              <strong>Partner: {pt.name}</strong>
                            </AccordionSummary>
                            {pt.desc && (
                              <AccordionDetails>
                                <p>{pt.desc || '(No description)'}</p>
                              </AccordionDetails>
                            )}
                          </Accordion>
                        ))}
                      </div>
                    )}

                    {eventDetails.location && eventDetails.location.length > 0 && (
                      <p style={{ marginTop: '5px' }}>
                        <strong>Location:</strong>{' '}
                        {eventDetails.location
                          .map((loc) => loc.location_addr)
                          .join(', ')}
                      </p>
                    )}
                  </div>
                )}
                <div className="time-slot-header">
                  <h3>
                    {language === 'zh'
                      ? translations.zh.step3.timeslotHeader.replace(
                          '{date}',
                          format(selectedDate, 'yyyy-MM-dd')
                        )
                      : translations.en.step3.timeslotHeader.replace(
                          '{date}',
                          format(selectedDate, 'EEEE, MMMM do, yyyy')
                        )}
                  </h3>
                </div>

                {loadingTimeslots ? (
                  <p>{translations[language].loadingTimeslots}</p>
                ) : performances.length === 0 ? (
                  <p>{translations[language].noTimeslots}</p>
                ) : (
                  <div className="timeslots-box">
                    <div className="new-timeslots-grid section-spacing">
                      {performances.map((perf) => {
                        const isBooked = perf.dj.length > 0;
                        const isBookedByUser = perf.dj.some(
                          (djObj) => djObj.username === username
                        );
                        const isSelected =
                          selectedPerformance && selectedPerformance.id === perf.id;

                        return (
                          <button
                            key={perf.id}
                            className={`new-timeslot-button ${
                              isBooked
                                ? isBookedByUser
                                  ? 'booked-by-user'
                                  : 'booked'
                                : isSelected
                                ? 'selected'
                                : ''
                            }`}
                            onClick={() => handlePerformanceSelection(perf)}
                            disabled={isBooked && !isBookedByUser}
                          >
                            {perf.time.slice(0, 5)}
                            {isBookedByUser && ' - You booked this slot'}
                          </button>
                        );
                      })}
                    </div>
                  </div>
                )}

                <div className="navigation-buttons" style={{ marginTop: '20px' }}>
                  <button
                    className="new-previous-button"
                    style={{ border: '2px solid black' }}
                    onClick={handlePrevious}
                    disabled={isPreviousLoading}
                  >
                    {isPreviousLoading ? (
                      <span className="spinner"></span>
                    ) : (
                      translations[language].step3.previous
                    )}
                  </button>
                  <button
                    className="new-next-button"
                    style={{ border: '2px solid black' }}
                    onClick={handleNext}
                    disabled={isNextLoading || !selectedPerformance}
                  >
                    {isNextLoading ? (
                      <span className="spinner"></span>
                    ) : (
                      translations[language].step3.next
                    )}
                  </button>
                </div>
              </div>
            )}
          </>
        )}

        {/* STEP 4 */}
        {step === 4 && (
          <>
            <h3>{translations[language].step4.header}</h3>
            <div className="new-performance-type-selection">
              <label>
                <input
                  type="radio"
                  name="performanceType"
                  checked={isLive === false}
                  onChange={() => setIsLive(false)}
                />
                {translations[language].step4.djSet}
              </label>
              <label>
                <input
                  type="radio"
                  name="performanceType"
                  checked={isLive === true}
                  onChange={() => setIsLive(true)}
                />
                {translations[language].step4.liveSet}
              </label>
            </div>

            {isLive === false && (
              <div className="new-mixer-model-selection">
                <label>
                  {translations[language].step4.mixerModelLabel}
                  <select
                    value={mixerModel}
                    onChange={(e) => setMixerModel(e.target.value)}
                  >
                    <option value="DJM-900 NXS2">DJM-900 NXS2</option>
                    <option value="XONE 96">XONE 96</option>
                  </select>
                </label>
              </div>
            )}

            <div className="navigation-buttons">
              <button
                className="new-previous-button"
                style={{ border: '2px solid black' }}
                onClick={handlePrevious}
                disabled={isPreviousLoading}
              >
                {isPreviousLoading ? (
                  <span className="spinner"></span>
                ) : (
                  translations[language].step4.previous
                )}
              </button>
              <button
                className="new-next-button"
                style={{ border: '2px solid black' }}
                onClick={handleNext}
                disabled={isNextLoading || isLive === null}
              >
                {isNextLoading ? (
                  <span className="spinner"></span>
                ) : (
                  translations[language].step4.next
                )}
              </button>
            </div>
          </>
        )}

        {/* STEP 5 */}
        {step === 5 && (
          <>
            <h3>{translations[language].step5.header}</h3>
            <div className="new-booking-summary">
              <p>
                <strong>{translations[language].step5.djName}:</strong>{' '}
                {djProfile?.name || username}
              </p>
              <p>
                <strong>{translations[language].step5.date}:</strong>{' '}
                {selectedDate ? format(selectedDate, 'yyyy-MM-dd') : 'N/A'}
              </p>
              {selectedPerformance ? (
                <p>
                  <strong>{translations[language].step5.time}:</strong>{' '}
                  {selectedPerformance.time.slice(0, 5)}
                </p>
              ) : (
                <p>No performance selected.</p>
              )}
              <p>
                <strong>{translations[language].step5.performanceType}:</strong>{' '}
                {isLive
                  ? translations[language].step5.liveSet
                  : translations[language].step5.djSet}
              </p>
              {!isLive && (
                <p>
                  <strong>{translations[language].step5.mixerModel}:</strong> {mixerModel}
                </p>
              )}
              <p
                style={{
                  fontStyle: 'italic',
                  marginTop: '1.5rem',
                  fontSize: '0.9rem'
                }}
              >
                Note: please arrive 10 mins before the show starts and make sure you
                have a stable internet connection (if applicable).
              </p>
            </div>

            {/* If can't book => show a warning */}
            {bookingMessage && !canBook && (
              <div
                className="booking-message warning"
                style={{ marginTop: '1rem' }}
              >
                {bookingMessage}
              </div>
            )}

            <div className="navigation-buttons">
              <button
                className="new-previous-button"
                style={{ border: '2px solid black' }}
                onClick={handlePrevious}
                disabled={isPreviousLoading}
              >
                {isPreviousLoading ? (
                  <span className="spinner"></span>
                ) : (
                  translations[language].step5.previous
                )}
              </button>
              <button
                className="new-confirm-button"
                onClick={handleBookPerformance}
                disabled={isBooking}
                style={{ border: '2px solid black' }}
              >
                {isBooking ? (
                  <span className="spinner"></span>
                ) : (
                  translations[language].step5.confirm
                )}
              </button>
            </div>
          </>
        )}
      </div>

      {/* Loading Overlay */}
      {isLoading && (
        <div className="new-loading-overlay">
          <img
            src="/static/img/loading.png"
            alt="Loading"
            className="new-loading-gif"
          />
        </div>
      )}
    </div>
  );
};

export default NewBooking;
