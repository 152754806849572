import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './OperationAdmin.css';
import './loading-overlay.css';
import { toast } from 'react-toastify';

import Cropper from 'react-easy-crop';
import Slider from '@mui/material/Slider';
import getCroppedImg from './cropImage';

const TABS = [
  { key: 'events', label: 'Events', endpoint: '/api/operation_admin/events', canCreate: false },
  { key: 'performances', label: 'Performances', endpoint: '/api/operation_admin/performance', canCreate: true },
  { key: 'djs', label: 'DJs', endpoint: '/api/operation_admin/djs', canCreate: true },
  { key: 'labels', label: 'Labels', endpoint: '/api/operation_admin/labels', canCreate: true },
  { key: 'partners', label: 'Partners', endpoint: '/api/operation_admin/partners', canCreate: true },
  { key: 'locations', label: 'Locations', endpoint: '/api/operation_admin/locations', canCreate: true },
];

const OperationAdmin = () => {
  const [activeTab, setActiveTab] = useState('events');
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [originalItem, setOriginalItem] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingSets, setLoadingSets] = useState(false);
  const [isCreatingNew, setIsCreatingNew] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [allEvents, setAllEvents] = useState([]); 
  const [allDjs, setAllDjs] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const predefinedTimes = [
    "00:00:00","00:30:00",
    "01:00:00","01:30:00",
    "02:00:00","02:30:00",
    "03:00:00","03:30:00",
    "04:00:00","04:30:00",
    "05:00:00","05:30:00",
    "06:00:00","06:30:00",
    "07:00:00","07:30:00",
    "08:00:00","08:30:00",
    "09:00:00","09:30:00",
    "10:00:00","10:30:00",
    "11:00:00","11:30:00",
    "12:00:00","12:30:00",
    "13:00:00","13:30:00",
    "14:00:00","14:30:00",
    "15:00:00","15:30:00",
    "16:00:00","16:30:00",
    "17:00:00","17:30:00",
    "18:00:00","18:30:00",
    "19:00:00","19:30:00",
    "20:00:00","20:30:00",
    "21:00:00","21:30:00",
    "22:00:00","22:30:00",
    "23:00:00","23:30:00"
  ];
  const mixerModels = [
    { value: 'DJM-900 NXS2', label: 'DJM-900 NXS2' },
    { value: 'XONE 96', label: 'XONE 96' }
  ];

  // Cropping states
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x:0, y:0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [showCropper, setShowCropper] = useState(false);
  const [currentImageField, setCurrentImageField] = useState(null); // 'profile_pic', 'label_logo', or 'logo'

  useEffect(() => {
    fetchItems(activeTab);
    if (activeTab === 'performances') {
      fetchAllEvents();
      fetchAllDjs();
    }
    if (activeTab === 'djs') {
      fetchAllUsers();
    }
  }, [activeTab]);

  useEffect(() => {
    applyFilter();
  }, [items, searchTerm]);

  const fetchItems = async (tabKey) => {
    const tab = TABS.find(t => t.key === tabKey);
    if (!tab) return;
    setLoading(true);
    setSelectedItem(null);
    setIsCreatingNew(false);
    setIsEditing(false);
    try {
      setLoadingSets(true);
      const response = await axios.get(tab.endpoint + '/', { withCredentials: true });
      let data = response.data;
      if (data && data.results) {
        data = data.results;
      }
      data = data || [];
      setItems(data);
    } catch (error) {
      console.error(`Error fetching ${tabKey}:`, error);
      toast.error(`Failed to fetch ${tabKey}`);
    } finally {
      setLoading(false);
      setLoadingSets(false);
    }
  };

  const fetchAllEvents = async () => {
    try {
      const res = await axios.get('/api/operation_admin/events/', { withCredentials: true });
      let data = res.data;
      if (data && data.results) data = data.results;
      setAllEvents(data || []);
    } catch (err) {
      console.error('Error fetching all events:', err);
      // Possibly show a toast if there's a 403/401
    }
  };

  const fetchAllDjs = async () => {
    try {
      const res = await axios.get('/api/operation_admin/djs', { withCredentials: true });
      let data = res.data;
      if (data && data.results) data = data.results;
      setAllDjs(data || []);
    } catch (err) {
      console.error('Error fetching all DJs:', err);
    }
  };

  const fetchAllUsers = async () => {
    try {
      const res = await axios.get('/api/users/', { withCredentials: true });
      let data = res.data;
      if (data && data.results) data = data.results;
      setAllUsers(data || []);
    } catch (err) {
      console.error('Error fetching all users:', err);
    }
  };

  const handleSelectItem = (item) => {
    setSelectedItem(item);
    setOriginalItem({ ...item });
    setIsCreatingNew(false);
    setIsEditing(false);
  };

  const handleFieldChange = (field, value) => {
    setSelectedItem(prev => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleDjSelectionChange = (e) => {
    const selectedOptions = Array.from(e.target.selectedOptions, option => option.value);
    setSelectedItem(prev => ({
      ...prev,
      dj_ids_write: selectedOptions
    }));
  };

  const handleCreateUserForDj = async () => {
    if (!selectedItem || !selectedItem.id) {
      toast.error('No DJ selected.');
      return;
    }
  
    try {
      // Call our custom endpoint
      const url = `/api/operation_admin/djs/${selectedItem.id}/create_user/`;
      const response = await axios.post(url, {}, { withCredentials: true });
      
      // The response returns the updated DJ object
      const updatedDj = response.data;
  
      // Update the items array if needed
      setItems((prevItems) =>
        prevItems.map((dj) => (dj.id === updatedDj.id ? updatedDj : dj))
      );
  
      // Also update 'selectedItem'
      setSelectedItem(updatedDj);
      setOriginalItem({ ...updatedDj });
  
      toast.success(`User created and assigned to ${updatedDj.name}`);
    } catch (error) {
      console.error('Error creating user for DJ:', error);
      if (error.response && error.response.data && error.response.data.detail) {
        toast.error(error.response.data.detail);
      } else {
        toast.error('Failed to create user.');
      }
    }
  };

  const handleCreateNew = () => {
    setSelectedItem({});
    setOriginalItem(null);
    setIsCreatingNew(true);
    setIsEditing(true);
  };

  const handleCancelEdit = () => {
    if (isCreatingNew) {
      setSelectedItem(null);
      setIsCreatingNew(false);
      setIsEditing(false);
    } else if (originalItem) {
      setSelectedItem(originalItem);
      setIsEditing(false);
    }
  };

  const handleImageChange = (e, imageFieldName) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setImageSrc(reader.result);
        setShowCropper(true);
        setCurrentImageField(imageFieldName);
      });
      reader.readAsDataURL(file);
    }
  };

  const handleSave = async () => {
    const tab = TABS.find(t => t.key === activeTab);
    if (!tab) return;
  
    if (!isCreatingNew && !selectedItem?.id) {
      toast.error('No item selected.');
      return;
    }
  
    const url = isCreatingNew
      ? `${tab.endpoint}/`
      : `${tab.endpoint}/${selectedItem.id}/`;
  
    try {
      let response;
  
      const method = isCreatingNew ? 'post' : 'patch';
  
      let payload = { ...selectedItem };
  
      // Step 1: Remove `id` field if present
      if (payload.id) {
        delete payload.id;
      }
  
      if (activeTab === 'performances') {
        if (payload.event_id_write) {
          payload.event = payload.event_id_write;
          delete payload.event_id_write;
        }
        delete payload.event_date;
        delete payload.event_name;
  
        response = await axios({
          url,
          method,
          data: payload,
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
        });
        toast.success(isCreatingNew ? 'Created successfully.' : 'Updated successfully.');
  
      } else if (activeTab === 'djs') {
        // Validate required fields if creating
        if (isCreatingNew) {
          if (!payload.name || !payload.name.trim()) {
            toast.error('Name is required.');
            return;
          }
          if (!payload.bio || !payload.bio.trim()) {
            toast.error('Bio is required.');
            return;
          }
          if (!payload.profile_pic || !(payload.profile_pic instanceof File)) {
            toast.error('Profile picture is required.');
            return;
          }
        }
  
        const formData = new FormData();
        if (payload.name) formData.append('name', payload.name);
        if (payload.bio) formData.append('bio', payload.bio);
  
        if (isCreatingNew || (payload.profile_pic instanceof File)) {
          formData.append('uploaded_profile_pic', payload.profile_pic);
        }
  
        formData.append('instagram', payload.instagram || '');
        formData.append('red', payload.red || '');
        formData.append('soundcloud', payload.soundcloud || '');
        formData.append('bandcamp', payload.bandcamp || '');
        formData.append('wechat', payload.wechat || '');
  
        // Add user_id if present
        if (payload.user_id) {
          formData.append('user_id', payload.user_id);
        } else {
          formData.append('user_id', '');
        }
  
        response = await axios({
          url,
          method,
          data: formData,
          withCredentials: true
        });
        toast.success(isCreatingNew ? 'Created successfully.' : 'Updated successfully.');
  
      } else if (activeTab === 'labels') {
        const isLabelLogoFile = payload.label_logo instanceof File;
        if (!isLabelLogoFile && typeof payload.label_logo === 'string') {
          // Not changing the logo
          delete payload.label_logo;
        }
  
        if (isLabelLogoFile) {
          const formData = new FormData();
          if (payload.label_name) formData.append('label_name', payload.label_name);
          if (payload.label_desc) formData.append('label_desc', payload.label_desc);
          if (payload.label_ig) formData.append('label_ig', payload.label_ig);
          if (payload.label_color) formData.append('label_color', payload.label_color);
          if (payload.nationality) formData.append('nationality', payload.nationality);
  
          formData.append('label_logo', payload.label_logo);
  
          response = await axios({
            url,
            method,
            data: formData,
            withCredentials: true
          });
        } else {
          response = await axios({
            url,
            method,
            data: payload,
            withCredentials: true
          });
        }
        toast.success(isCreatingNew ? 'Created successfully.' : 'Updated successfully.');
  
      } else if (activeTab === 'partners') {
        const isPartnerLogoFile = payload.logo instanceof File;
        if (!isPartnerLogoFile && typeof payload.logo === 'string') {
          // Not changing the logo
          delete payload.logo;
        }
  
        if (isPartnerLogoFile) {
          const formData = new FormData();
          if (payload.name) formData.append('name', payload.name);
          if (payload.partner_ig) formData.append('partner_ig', payload.partner_ig);
          if (payload.desc) formData.append('desc', payload.desc);
          if (payload.color) formData.append('color', payload.color);
  
          formData.append('logo', payload.logo);
  
          response = await axios({
            url,
            method,
            data: formData,
            withCredentials: true
          });
        } else {
          response = await axios({
            url,
            method,
            data: payload,
            withCredentials: true
          });
        }
        toast.success(isCreatingNew ? 'Created successfully.' : 'Updated successfully.');
  
      } else if (activeTab === 'locations') {
        response = await axios({
          url,
          method,
          data: payload,
          withCredentials: true
        });
        toast.success(isCreatingNew ? 'Created successfully.' : 'Updated successfully.');
  
      } else {
        // For events or others (if any)
        response = await axios({
          url,
          method,
          data: payload,
          withCredentials: true
        });
        toast.success(isCreatingNew ? 'Created successfully.' : 'Updated successfully.');
      }
  
      const savedItem = response.data; 
      const savedId = savedItem.id;
  
      // Update the items list locally without re-fetching
      setItems((prevItems) => {
        let newItems;
        if (isCreatingNew) {
          // Insert at the top or append at the end
          newItems = [savedItem, ...prevItems];
        } else {
          // Update existing item
          newItems = prevItems.map((it) => (it.id === savedId ? savedItem : it));
        }
        return newItems;
      });
  
      // Select the saved/updated item and show detail view
      setSelectedItem(savedItem);
      setOriginalItem({ ...savedItem });
  
      // Turn off creating/editing mode to show detail view mode
      setIsCreatingNew(false);
      setIsEditing(false);
  
    } catch (error) {
      console.error('Error saving item:', error);
      toast.error('Error saving changes.');
    }
  };

  const handleEditMode = () => {
    if (selectedItem && selectedItem.id && activeTab !== 'events') {
      setIsEditing(true);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const applyFilter = () => {
    if (!searchTerm) {
      setFilteredItems(items);
      return;
    }
    const term = searchTerm.toLowerCase();
    const filtered = items.filter(item => {
      const label = renderListItem(item).toLowerCase();
      return label.includes(term);
    });
    setFilteredItems(filtered);
  };

  const currentTab = TABS.find(t => t.key === activeTab);
  const canCreate = currentTab?.canCreate;
  const readOnly = (activeTab === 'events') || (!isCreatingNew && !isEditing);

  const renderInstructionForTab = () => {
    if (activeTab === 'events') {
      return <p className="field-instructions">All fields read-only. You cannot edit events.</p>;
    }

    if (activeTab === 'performances') {
      return (
        <p className="field-instructions">
          <strong>Required:</strong> event (choose when creating), time, mixer_model, is_live.<br />
          When viewing existing performance, event date and name are read-only.<br />
          Optional fields can be left blank. You can also edit DJs.
        </p>
      );
    }

    if (activeTab === 'djs') {
      return (
        <p className="field-instructions">
          <strong>Required (for create):</strong> Name, Bio, Profile Picture (Square Crop)<br />
          <strong>Optional:</strong> Instagram, red, soundcloud, wechat
        </p>
      );
    }

    if (activeTab === 'labels') {
      return (
        <p className="field-instructions">
          <strong>Required:</strong> Label Name<br />
          <strong>Optional:</strong> Label Desc, Label Color, Label Logo (Free-form crop)
        </p>
      );
    }

    if (activeTab === 'partners') {
      return (
        <p className="field-instructions">
          <strong>Required:</strong> Name<br />
          <strong>Optional:</strong> Desc, Color, Logo (Free-form crop)
        </p>
      );
    }

    if (activeTab === 'locations') {
      return (
        <p className="field-instructions">
          <strong>Required:</strong> Location Name<br />
          <strong>Optional:</strong> Address
        </p>
      );
    }

    return null;
  };

  const formatTime12h = (timeStr) => {
    if (!timeStr) return '';
    const [hour, minute] = timeStr.split(':');
    let h = parseInt(hour,10);
    const ampm = h >= 12 ? 'pm' : 'am';
    if (h === 0) h = 12;
    else if (h > 12) h = h - 12;
    return (minute === '00') ? `${h}${ampm}` : `${h}:${minute}${ampm}`;
  };

  const renderListItem = (item) => {
    if (activeTab === 'events') {
      return `wk.${item.week_no || ''} ${item.event_name || item.id}`;
    } else if (activeTab === 'performances') {
      const djNames = item.dj && item.dj.length > 0
        ? item.dj.map(d => d.name).join(', ')
        : 'No DJ';
      const eventName = item.event_name || 'No event name';
      const timeStr = formatTime12h(item.time);
      return `wk.${item.event_week_no || ''} ${timeStr} ${djNames} | ${eventName}`;
    } else if (activeTab === 'djs') {
      return `${item.name || item.id}`;
    } else if (activeTab === 'labels') {
      return `${item.label_name || item.id}`;
    } else if (activeTab === 'partners') {
      return `${item.name || item.id}`;
    } else if (activeTab === 'locations') {
      return `${item.location_name || item.id}`;
    }
    return item.id;
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const showCroppedImage = async () => {
    try {
      // Decide which format to use based on the current field
      // e.g. if field is 'label_logo' or 'logo', we use PNG
      // else we use JPEG
      let outputFormat = 'image/jpeg';
      if (currentImageField === 'label_logo' || currentImageField === 'logo') {
        outputFormat = 'image/png';
      }
  
      const croppedImageBlob = await getCroppedImg(
        imageSrc,
        croppedAreaPixels,
        {
          outputFormat: outputFormat, 
          maxWidth: 1200,
        }
      );
  
      const fileExtension = (outputFormat === 'image/png') ? 'png' : 'jpg';
      const file = new File([croppedImageBlob], `croppedImage.${fileExtension}`, { type: outputFormat });
  
      setShowCropper(false);
  
      // Now store that file in your state
      setSelectedItem(prev => ({
        ...prev,
        [currentImageField]: file,
      }));
      setCurrentImageField(null);
  
    } catch (e) {
      console.error(e);
      toast.error('Failed to crop the image.');
    }
  };

  // Fields rendering functions
  const renderDjFields = () => {
    const disabledProp = { disabled: readOnly };

    return (
      <>
        <label>
          Name (required):
          <input
            type="text"
            value={selectedItem.name || ''}
            onChange={(e) => handleFieldChange('name', e.target.value)}
            {...disabledProp}
          />
        </label>
        <label>
          Bio (required):
          <textarea
            value={selectedItem.bio || ''}
            onChange={(e) => handleFieldChange('bio', e.target.value)}
            {...disabledProp}
          />
        </label>

        {selectedItem.profile_pic && typeof selectedItem.profile_pic === 'string' && (
          <div className="current-profile-pic">
            <p>Current Profile Picture:</p>
            <img
              src={selectedItem.profile_pic}
              alt="Current Profile"
              style={{ width: '100px', height: '100px', objectFit: 'cover' }}
            />
          </div>
        )}

        <label>
          Profile Picture (required if creating):
          <input
            type="file"
            name="uploaded_profile_pic"
            accept="image/*"
            onChange={(e) => handleImageChange(e, 'profile_pic')}
            {...disabledProp}
          />
        </label>

        {selectedItem.profile_pic && selectedItem.profile_pic instanceof File && (
          <p>New profile picture selected.</p>
        )}

        <label>
          Instagram (optional):
          <input
            type="text"
            value={selectedItem.instagram || ''}
            onChange={(e) => handleFieldChange('instagram', e.target.value)}
            {...disabledProp}
          />
        </label>

        <label>
          red (optional):
          <input
            type="text"
            value={selectedItem.red || ''}
            onChange={(e) => handleFieldChange('red', e.target.value)}
            {...disabledProp}
          />
        </label>

        <label>
          soundcloud (optional):
          <input
            type="text"
            value={selectedItem.soundcloud || ''}
            onChange={(e) => handleFieldChange('soundcloud', e.target.value)}
            {...disabledProp}
          />
        </label>

        <label>
          wechat (optional):
          <input
            type="text"
            value={selectedItem.wechat || ''}
            onChange={(e) => handleFieldChange('wechat', e.target.value)}
            {...disabledProp}
          />
        </label>

        <label>
          User (read-only):
          <div style={{ marginTop: '5px' }}>
            {selectedItem?.user_id ? (
              // If a user is associated, show the username or user_id
              <p>
                Assigned to user ID: {selectedItem.user_username}
                {/* If your API returns user details (e.g. user.username) 
                    you could display user’s username as well */}
              </p>
            ) : (
              // No user assigned => show a message and a 'Create User' button
              <>
                <p>No User associated with this DJ yet.</p>
                {/* Only show button if not in create mode 
                    and not editing mode (if you prefer) */}
                {!isCreatingNew && !isEditing && (
                  <button type="button" onClick={handleCreateUserForDj}>
                    Create User
                  </button>
                )}
              </>
            )}
          </div>
        </label>
      </>
    );
  };
  

  const renderLabelFields = () => {
    const disabledProp = { disabled: readOnly };
    return (
      <>
        <label>
          Label Name (required):
          <input
            type="text"
            value={selectedItem.label_name || ''}
            onChange={(e) => handleFieldChange('label_name', e.target.value)}
            {...disabledProp}
          />
        </label>
        <label>
          Label Desc (optional):
          <textarea
            value={selectedItem.label_desc || ''}
            onChange={(e) => handleFieldChange('label_desc', e.target.value)}
            {...disabledProp}
          />
        </label>
        <label>
          Label IG (optional):
          <input
            type="text"
            value={selectedItem.label_ig || ''}
            onChange={(e) => handleFieldChange('label_ig', e.target.value)}
            {...disabledProp}
          />
        </label>
        <label>
          Nationality (optional):
          <input
            type="text"
            value={selectedItem.nationality || ''}
            onChange={(e) => handleFieldChange('nationality', e.target.value)}
            {...disabledProp}
          />
        </label>
        <label>
          Label Color (optional):
          <input
            type="text"
            value={selectedItem.label_color || ''}
            onChange={(e) => handleFieldChange('label_color', e.target.value)}
            {...disabledProp}
          />
        </label>
  
        {selectedItem.label_logo && typeof selectedItem.label_logo === 'string' && (
          <div className="current-profile-pic">
            <p>Current Label Logo:</p>
            <img
              src={selectedItem.label_logo}
              alt="Current Label Logo"
              style={{ width: '100px', height: 'auto', objectFit: 'contain' }}
            />
          </div>
        )}
  
        <label>
          Label Logo (optional):
          <input
            type="file"
            accept="image/*"
            onChange={(e) => handleImageChange(e, 'label_logo')}
            {...disabledProp}
          />
        </label>
  
        {selectedItem.label_logo && selectedItem.label_logo instanceof File && (
          <p>New label logo selected.</p>
        )}
      </>
    );
  };

  const renderPartnerFields = () => {
    const disabledProp = { disabled: readOnly };
    return (
      <>
        <label>
          Name (required):
          <input
            type="text"
            value={selectedItem.name || ''}
            onChange={(e) => handleFieldChange('name', e.target.value)}
            {...disabledProp}
          />
        </label>
        <label>
          Desc (optional):
          <textarea
            value={selectedItem.desc || ''}
            onChange={(e) => handleFieldChange('desc', e.target.value)}
            {...disabledProp}
          />
        </label>
        <label>
          Partner IG (optional):
          <input
            type="text"
            value={selectedItem.partner_ig || ''}
            onChange={(e) => handleFieldChange('partner_ig', e.target.value)}
            {...disabledProp}
          />
        </label>
        <label>
          Color (optional):
          <input
            type="text"
            value={selectedItem.color || ''}
            onChange={(e) => handleFieldChange('color', e.target.value)}
            {...disabledProp}
          />
        </label>
  
        {selectedItem.logo && typeof selectedItem.logo === 'string' && (
          <div className="current-profile-pic">
            <p>Current Partner Logo:</p>
            <img
              src={selectedItem.logo}
              alt="Current Partner Logo"
              style={{ width: '100px', height: 'auto', objectFit: 'contain' }}
            />
          </div>
        )}
  
        <label>
          Partner Logo (optional):
          <input
            type="file"
            accept="image/*"
            onChange={(e) => handleImageChange(e, 'logo')}
            {...disabledProp}
          />
        </label>
  
        {selectedItem.logo && selectedItem.logo instanceof File && (
          <p>New partner logo selected.</p>
        )}
      </>
    );
  };

  const renderPerformanceFields = () => {
    const disabledProp = { disabled: readOnly };
  
    // For DJ selection (unchanged)
    const selectedDjs = selectedItem?.dj || [];
    const selectedDjIds = selectedDjs.map(d => d.id);
    const currentlySelected = selectedItem?.dj_ids_write || selectedDjIds;
  
    // 1) Figure out which event ID to show:
    //    - If user has updated 'event_id_write', show that
    //    - Otherwise, fall back to the existing performance’s `event_id`
    const eventValue = 
      selectedItem.event_id_write !== undefined
        ? selectedItem.event_id_write
        : (selectedItem.event_id !== undefined ? selectedItem.event_id : "");
  
    return (
      <>
        {/* Always show an editable Event dropdown */}
        <label>
          Event (required):
          <select
            value={eventValue}
            onChange={(e) => handleFieldChange('event_id_write', e.target.value)}
            {...disabledProp}
          >
            <option value="">Select Event</option>
            {allEvents.map(ev => (
              <option key={ev.id} value={ev.id}>
                wk.{ev.week_no} {ev.event_name}
              </option>
            ))}
          </select>
        </label>
  
        <label>
          DJs:
          <select
            multiple
            value={currentlySelected}
            onChange={handleDjSelectionChange}
            {...disabledProp}
          >
            {allDjs.map(dj => (
              <option key={dj.id} value={dj.id}>
                {dj.name}
              </option>
            ))}
          </select>
        </label>
  
        <label>
          Date (optional):
          <input
            type="date"
            value={selectedItem.date || ""}     // Ensure it's a valid YYYY-MM-DD string
            onChange={(e) => handleFieldChange("date", e.target.value)}
            {...disabledProp}
          />
        </label>

        <label>
          Time (required):
          <select
            value={selectedItem.time || ""}
            onChange={(e) => handleFieldChange("time", e.target.value)}
            {...disabledProp}
          >
            <option value="">Select Time</option>
            {predefinedTimes.map((t) => (
              <option key={t} value={t}>
                {t}
              </option>
            ))}
          </select>
        </label>
  
        <label>
          Mixer Model (required):
          <select
            value={selectedItem.mixer_model || ""}
            onChange={(e) => handleFieldChange("mixer_model", e.target.value)}
            {...disabledProp}
          >
            <option value="">Select Mixer Model</option>
            {mixerModels.map((mm) => (
              <option key={mm.value} value={mm.value}>
                {mm.label}
              </option>
            ))}
          </select>
        </label>
  
        <label>
          Set Type (required):
          <select
            value={
              selectedItem.is_live === undefined
                ? "false"
                : selectedItem.is_live.toString()
            }
            onChange={(e) => handleFieldChange("is_live", e.target.value === "true")}
            {...disabledProp}
          >
            <option value="false">DJ Set</option>
            <option value="true">Live Set</option>
          </select>
        </label>

        <label>
          Baidu Pan Link (optional):
          <input
            type="text"
            value={selectedItem.baidu_pan_link || ''}
            onChange={(e) => handleFieldChange('baidu_pan_link', e.target.value)}
            {...disabledProp}
          />
        </label>
      </>
    );
  };

  const renderFieldsForTab = () => {
    if (!selectedItem && !isCreatingNew) return null;

    if (activeTab === 'performances') {
      return renderPerformanceFields();
    }

    if (activeTab === 'events') {
      return (
        <>
          <label>Event Name (read-only): <input type="text" value={selectedItem.event_name || ''} readOnly /></label>
          <label>Date (YYYY-MM-DD, read-only): <input type="text" value={selectedItem.date || ''} readOnly /></label>
          <label>Week No (read-only): <input type="number" value={selectedItem.week_no || ''} readOnly /></label>
          <label>Program (read-only): <input type="text" value={selectedItem.program || ''} readOnly /></label>
          <label>Color (read-only): <input type="text" value={selectedItem.color || ''} readOnly /></label>

          {selectedItem.event_bg && (
            <div className="current-event-bg" style={{ marginTop: '10px' }}>
            <p>Current Event Background:</p>
            <img
                src={selectedItem.event_bg}
                alt="Event Background"
                style={{ width: '200px', height: 'auto', objectFit: 'contain' }}
            />
            </div>
          )}
          <br></br>
            <button type="button" onClick={handleDownloadWechatMaterial} disabled={!selectedItem || !selectedItem.id}>
                Download WeChat Material
            </button>
            <button 
            type="button" 
            onClick={handleDownloadLineupVideo} 
            disabled={!selectedItem || !selectedItem.id}
            style={{ marginLeft: '10px' }}
            >
            Download Lineup Video
            </button>
            <button
                type="button"
                onClick={handleDownloadGuestMaterials}
                disabled={!selectedItem || !selectedItem.id}
                style={{ marginLeft: '10px' }}
            >
                Download Guest Materials
            </button>
        </>
      );
    }

    if (activeTab === 'djs') {
      return renderDjFields();
    }

    if (activeTab === 'labels') {
      return renderLabelFields();
    }

    if (activeTab === 'partners') {
      return renderPartnerFields();
    }

    if (activeTab === 'locations') {
      const disabledProp = { disabled: readOnly };
      return (
        <>
          <label>
            Location Name (required):
            <input
              type="text"
              value={selectedItem.location_name || ''}
              onChange={(e) => handleFieldChange('location_name', e.target.value)}
              {...disabledProp}
            />
          </label>
          <label>
            Address (optional):
            <input
              type="text"
              value={selectedItem.location_addr || ''}
              onChange={(e) => handleFieldChange('location_addr', e.target.value)}
              {...disabledProp}
            />
          </label>
        </>
      );
    }

    return null;
  };

  // Determine aspect ratio for cropping
  const getAspectForCrop = () => {
    // If cropping DJ profile_pic or event_bg (if implemented), aspect=1
    // If cropping label_logo or logo (partners), no aspect (free-form)
    if (currentImageField === 'profile_pic') {
      return 1; // Square for DJs
    }
    // If label_logo or logo, omit aspect for free-form
    if (currentImageField === 'label_logo' || currentImageField === 'logo') {
      return undefined; // free-form
    }
    return 1; // default to 1 for any other fields if needed
  };


  const handleDownloadWechatMaterial = async () => {
    if (!selectedItem || !selectedItem.id) {
      toast.error('No event selected.');
      return;
    }
  
    const url = `/api/operation_admin/events/${selectedItem.id}/download_graphics/`;
    try {
      const response = await axios.get(url, {
        withCredentials: true,
        responseType: 'blob' // important to get a binary file
      });
      // Create a URL for the blob
      const blobUrl = URL.createObjectURL(response.data);
      // Create a temporary link element to simulate a click download
      const link = document.createElement('a');
      link.href = blobUrl;
  
      // The server sets the filename in the Content-Disposition header, but if needed, 
      // we can guess or set a default filename here. 
      // Let's try extracting from 'content-disposition' if provided:
      const contentDisposition = response.headers['content-disposition'];
      let filename = 'wechat_materials.zip';
      if (contentDisposition) {
        const match = contentDisposition.match(/filename="(.+)"/);
        if (match && match[1]) {
          filename = match[1];
        }
      }
      link.setAttribute('download', filename);
  
      document.body.appendChild(link);
      link.click();
      // Clean up
      document.body.removeChild(link);
      URL.revokeObjectURL(blobUrl);
      toast.success('Download started.');
    } catch (error) {
      console.error('Error downloading wechat material:', error);
      toast.error('Failed to download wechat material.');
    }
  };

  const handleDownloadGuestMaterials = async () => {
    if (!selectedItem || !selectedItem.id) {
      toast.error('No event selected.');
      return;
    }
  
    const url = `/api/operation_admin/events/${selectedItem.id}/download_guest_materials/`;
  
    try {
      const response = await axios.get(url, {
        withCredentials: true,
        responseType: 'blob', // Must get binary data as a blob
      });
  
      // Create a URL for the blob
      const blobUrl = URL.createObjectURL(response.data);
  
      // Create a temporary link element to simulate a click "download"
      const link = document.createElement('a');
      link.href = blobUrl;
  
      // Attempt to parse filename from the "content-disposition" header
      const contentDisposition = response.headers['content-disposition'];
      let filename = 'guest_materials.zip';
      if (contentDisposition) {
        const match = contentDisposition.match(/filename="(.+)"/);
        if (match && match[1]) {
          filename = match[1];
        }
      }
      link.setAttribute('download', filename);
  
      document.body.appendChild(link);
      link.click();
  
      // Cleanup
      document.body.removeChild(link);
      URL.revokeObjectURL(blobUrl);
  
      toast.success('Guest Materials download started.');
    } catch (error) {
      console.error('Error downloading guest materials:', error);
      toast.error('Failed to download guest materials.');
    }
  };

  const handleDownloadLineupVideo = async () => {
    if (!selectedItem || !selectedItem.id) {
      toast.error('No event selected.');
      return;
    }
  
    // Suppose your new API endpoint is:
    // /api/operation_admin/events/<event_id>/download_lineup_video/
    const url = `/api/operation_admin/events/${selectedItem.id}/download_lineup_video/`;
  
    try {
      const response = await axios.get(url, {
        withCredentials: true,
        responseType: 'blob', // must get binary data as a blob
      });
  
      // Create a URL for the blob
      const blobUrl = URL.createObjectURL(response.data);
  
      // Create a temporary link element to simulate a click "download"
      const link = document.createElement('a');
      link.href = blobUrl;
  
      // Attempt to parse filename from the "content-disposition" header
      const contentDisposition = response.headers['content-disposition'];
      let filename = 'lineup_video.mp4';
      if (contentDisposition) {
        const match = contentDisposition.match(/filename="(.+)"/);
        if (match && match[1]) {
          filename = match[1];
        }
      }
      link.setAttribute('download', filename);
  
      document.body.appendChild(link);
      link.click();
  
      // Cleanup
      document.body.removeChild(link);
      URL.revokeObjectURL(blobUrl);
  
      toast.success('Lineup video download started.');
    } catch (error) {
      console.error('Error downloading lineup video:', error);
      toast.error('Failed to download lineup video.');
    }
  };
  
  return (
    <div className="operation-admin-container">
      {loadingSets && (
        <div className="loading-overlay">
          <img src="static/img/loading.png" alt="Loading" className="loading-gif" />
        </div>
      )}
      <h2>Operation Admin</h2>
      <div className="tabs-bar">
        {TABS.map(tab => (
          <button
            key={tab.key}
            className={`tab-button ${activeTab === tab.key ? 'active' : ''}`}
            onClick={() => setActiveTab(tab.key)}
          >
            {tab.label}
          </button>
        ))}
      </div>

      {loading && <div className="loading-indicator">Loading...</div>}
      {!loading && (
        <div className="admin-content">
          <div className="sidebar">
            <div className="sidebar-header">
              <h3>{TABS.find(t=>t.key===activeTab)?.label} List</h3>
              {canCreate && (
                <button className="create-button" onClick={handleCreateNew}>Create New</button>
              )}
            </div>
            <div className="search-container">
              <input
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
            <ul className="item-list">
              {filteredItems && filteredItems.length === 0 && (
                <li className="no-items">No items found.</li>
              )}
              {(filteredItems || []).map(item => (
                <li
                  key={item.id}
                  className={selectedItem?.id === item.id ? 'selected' : ''}
                  onClick={() => handleSelectItem(item)}
                >
                  {renderListItem(item)}
                </li>
              ))}
            </ul>
          </div>
          <div className="detail-panel">
            <h3>Details</h3>
            {renderInstructionForTab()}
            {(selectedItem || isCreatingNew) ? (
              <div className="detail-form">
                {renderFieldsForTab()}
                <div className="buttons-row">
                  {
                    (isCreatingNew || isEditing) && activeTab !== 'events' && (
                      <>
                        <button className="save-button" onClick={handleSave}>
                          {isCreatingNew ? 'Create Item' : 'Save Changes'}
                        </button>
                        <button className="cancel-button" onClick={handleCancelEdit}>Cancel</button>
                      </>
                    )
                  }

                  {
                    (selectedItem && !isEditing && !isCreatingNew && activeTab !== 'events') && (
                      <button className="edit-button" onClick={handleEditMode}>Edit</button>
                    )
                  }

                  {activeTab === 'events' && selectedItem && (
                    <p className="read-only-hint">Events are read-only.</p>
                  )}
                </div>
              </div>
            ) : (
              <p>Select an item to view details or click "Create New" to add a new one.</p>
            )}
          </div>
        </div>
      )}

      {showCropper && (
        <div className="cropper-modal">
          <div className="cropper-wrapper">
            <div className="cropper-container">
                <Cropper
                image={imageSrc}
                crop={crop}
                zoom={zoom}
                aspect={1}     // free-form shape (already set, but re-check)
                minZoom={0.1}          // user can now zoom OUT down to 0.1x
                maxZoom={10}           // user can zoom in more if needed
                restrictPosition={false}
                objectFit="contain"    // ensure image is contained in the box
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={onCropComplete}
                />
            </div>
            <div className="cropper-controls">
              <div className="slider-container">
                <Slider
                  value={zoom}
                  min={1}
                  max={3}
                  step={0.1}
                  onChange={(e, z) => setZoom(z)}
                />
              </div>
              <div className="button-group">
                <button onClick={showCroppedImage}>Crop Image</button>
                <button onClick={() => {setShowCropper(false);setCurrentImageField(null);}}>Cancel</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OperationAdmin;
