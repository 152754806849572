// src/bookings/MyBookings.js

import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { format, isAfter, addDays, parseISO } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import './MyBookings.css';
import { AuthContext } from '../AuthContext'; // <-- import AuthContext

const MyBookings = () => {
  // Chinese default text
  const [userBookings, setUserBookings] = useState([]);
  const [loadingBookings, setLoadingBookings] = useState(true);

  const navigate = useNavigate();

  // Destructure userRoles from the AuthContext
  const { userRoles } = useContext(AuthContext);

  // Decide if user can see “Book a new session” card
  // e.g. "superuser", "admin", "operation" => allowed
  const canBook =
    userRoles.includes('superuser') ||
    userRoles.includes('operation_admin');

  useEffect(() => {
    const fetchUserBookings = async () => {
      setLoadingBookings(true);
      try {
        const response = await axios.get('/api/booking/my_bookings/');
        setUserBookings(response.data);
      } catch (error) {
        console.error('Error fetching user bookings:', error);
        toast.error('获取你的预约时出错！');
      } finally {
        setLoadingBookings(false);
      }
    };
    fetchUserBookings();
  }, []);

  const handleCancelBooking = async (performanceId) => {
    if (!window.confirm('确定要取消这个预约吗？')) {
      return;
    }
    if (!window.confirm('此操作无法恢复，点击"OK"确认继续。')) {
      return;
    }
    try {
      await axios.post('/api/booking/cancel_booking/', {
        performance_id: performanceId,
      });
      toast.success('成功取消预约。');
      setUserBookings((prev) => prev.filter((b) => b.id !== performanceId));
    } catch (error) {
      console.error('Error canceling booking:', error);
      toast.error('取消预约时出错！');
    }
  };

  if (loadingBookings) {
    return (
      <div className="my-bookings-container">
        <h2>我的预约</h2>
        <p>正在加载...</p>
      </div>
    );
  }

  // If no bookings
  if (!loadingBookings && userBookings.length === 0) {
    return (
      <div className="my-bookings-container">
        <h2>我的预约</h2>
        <div className="my-bookings-list">
          {/* Only show the “new booking” card if user canBook */}
          {canBook && (
            <div
              className="booking-card new-booking-card"
              onClick={() => navigate('/dj/booking/new')}
            >
              <div className="plus-sign">+</div>
              <div className="new-booking-text">预约一个新的档期</div>
            </div>
          )}
        </div>
        <p>你还没有任何预约哦</p>
      </div>
    );
  }

  return (
    <div className="my-bookings-container">
      <h2>我的预约</h2>

      {/* Cards grid */}
      <div className="my-bookings-list">
        {/* First Card => Create New Booking (ONLY if user canBook) */}
        {canBook && (
          <div
            className="booking-card new-booking-card"
            onClick={() => navigate('/dj/booking/new')}
          >
            <div className="plus-sign">+</div>
            <div className="new-booking-text">预约一个新的档期</div>
          </div>
        )}

        {userBookings.map((booking) => {
          const eventDate = parseISO(booking.event_date);
          const today = new Date();
          const tenDaysLater = addDays(today, 10);
          const isFutureBooking = isAfter(eventDate, today);
          const canCancel = isFutureBooking && isAfter(eventDate, tenDaysLater);

          // Combine date + time, e.g. 2024.09.17 @ 19:00
          const dateTimeString = `${format(eventDate, 'yyyy.MM.dd')} @ ${
            booking.time
          }`;

          // If is_live => "Live Set", else "DJ Set"
          const performanceType = booking.is_live ? 'Live Set' : 'DJ Set';

          return (
            <div key={booking.id} className="booking-card">
              {/* Thumbnail on the left */}
              {booking.square_thumbnail_url ? (
                <img
                  src={booking.square_thumbnail_url}
                  alt="Thumbnail"
                  className="booking-thumbnail"
                />
              ) : (
                <div className="booking-thumbnail placeholder" />
              )}

              {/* Info on the right */}
              <div className="booking-content">
                <div className="booking-line date-time">{dateTimeString}</div>
                <div className="booking-line event-name">
                  {booking.event_name || 'N/A'}
                </div>
                <div className="booking-line performance-type">
                  {performanceType}
                </div>

                {/* Buttons row */}
                <div className="booking-card-buttons">
                  <button
                    onClick={() => navigate(`/dj/booking/${booking.id}`)}
                    className="detail-button"
                  >
                    详情
                  </button>
                  {canCancel && (
                    <button
                      onClick={() => handleCancelBooking(booking.id)}
                      className="cancel-booking-button"
                    >
                      取消
                    </button>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MyBookings;
