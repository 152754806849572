import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import {
  Box,
  Grid,
  Paper,
  Typography,
  CircularProgress,
  Slider,
  TextField,
  Button,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useParams, useNavigate } from 'react-router-dom';
import './MaterialAdminDetail.css';

/**
 * Real-time color adjustments for screenshot previews.
 * You already have this logic. No changes needed except variable naming, if you prefer.
 */

function isValidUrlString(str) {
  // Return true if str is non-empty, non-null, and not just whitespace
  return typeof str === 'string' && str.trim().length > 0;
}

function adjustColorsJS(imageData, width, height, {
  brightness = 0,
  contrast = 1.0,
  saturation = 1.0,
  r = 0,
  g = 0,
  b = 0
}) {
  const dataF32 = new Float32Array(imageData);

  // 1) brightness
  for (let i=0; i<dataF32.length; i+=4) {
    dataF32[i]   += brightness;
    dataF32[i+1] += brightness;
    dataF32[i+2] += brightness;
  }

  // 2) contrast around mean
  let sum=0, count=0;
  for (let i=0; i<dataF32.length; i+=4) {
    const avg = (dataF32[i]+dataF32[i+1]+dataF32[i+2])/3.0;
    sum += avg; count++;
  }
  const globalMean = sum/count;
  for (let i=0; i<dataF32.length; i+=4) {
    dataF32[i]   = (dataF32[i]   - globalMean)*contrast + globalMean;
    dataF32[i+1] = (dataF32[i+1] - globalMean)*contrast + globalMean;
    dataF32[i+2] = (dataF32[i+2] - globalMean)*contrast + globalMean;
  }

  // 3) per-channel offsets
  for (let i=0; i<dataF32.length; i+=4) {
    dataF32[i]   -= r;
    dataF32[i+1] -= g;
    dataF32[i+2] -= b;
  }

  // clamp [0..255]
  for (let i=0; i<dataF32.length; i+=4) {
    dataF32[i]   = Math.min(255, Math.max(0, dataF32[i]));
    dataF32[i+1] = Math.min(255, Math.max(0, dataF32[i+1]));
    dataF32[i+2] = Math.min(255, Math.max(0, dataF32[i+2]));
  }

  // 4) saturation in HSV
  for (let i=0; i<dataF32.length; i+=4) {
    const R = dataF32[i], G = dataF32[i+1], B = dataF32[i+2];
    const max = Math.max(R,G,B), min = Math.min(R,G,B);
    const delta = max-min;
    let h=0, s=0, v=max;
    if (max!==0) s=delta/max;
    if (delta!==0) {
      if (max===R) {
        h=60*(((G-B)/delta)%6);
      } else if (max===G) {
        h=60*((B-R)/delta+2);
      } else {
        h=60*((R-G)/delta+4);
      }
    }
    if (h<0) h += 360;
    s *= saturation;
    s = Math.min(1, Math.max(0,s));

    // HSV->RGB
    const C = s*v, X = C*(1-Math.abs(((h/60)%2)-1)), m=v-C;
    let Rp=0, Gp=0, Bp=0;
    if (0<=h && h<60)      [Rp,Gp,Bp]=[C,X,0];
    else if (60<=h && h<120) [Rp,Gp,Bp]=[X,C,0];
    else if (120<=h && h<180) [Rp,Gp,Bp]=[0,C,X];
    else if (180<=h && h<240) [Rp,Gp,Bp]=[0,X,C];
    else if (240<=h && h<300) [Rp,Gp,Bp]=[X,0,C];
    else [Rp,Gp,Bp]=[C,0,X];

    dataF32[i]   = (Rp+m);
    dataF32[i+1] = (Gp+m);
    dataF32[i+2] = (Bp+m);
  }
  // clamp final
  for (let i=0; i<dataF32.length; i+=4) {
    dataF32[i]   = Math.min(255, Math.max(0, dataF32[i]));
    dataF32[i+1] = Math.min(255, Math.max(0, dataF32[i+1]));
    dataF32[i+2] = Math.min(255, Math.max(0, dataF32[i+2]));
  }

  // convert back to 8-bit
  const out = new Uint8ClampedArray(dataF32.length);
  for (let i=0; i<dataF32.length; i++) {
    if ((i+1) % 4 === 0) {
      out[i] = 255; // alpha = 255
    } else {
      out[i] = dataF32[i];
    }
  }
  return out;
}

/** Load an image from a given URL (could be local or remote). */
function loadImage(url) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = "anonymous";
    img.onload = () => resolve(img);
    img.onerror = err => reject(err);
    img.src = url;
  });
}

/** Helper to see if performance time < 18:00 => black text, else white. */
function isBefore6PM(timeStr) {
  if (!timeStr) return true;
  const [hh] = timeStr.split(":");
  const hour = parseInt(hh,10);
  return (hour<18);
}

const MaterialAdminDetail = () => {
  const { eventId } = useParams();
  const navigate = useNavigate();

  const [eventData, setEventData] = useState(null);
  const [loading, setLoading]   = useState(true);
  const [error, setError]       = useState(null);

  // Sliders
  const [paramsByPerf, setParamsByPerf] = useState({});

  // We store a canvas ref for each performance
  const canvasRefs = useRef({});
  const [imgCache, setImgCache] = useState({});

  // For highlight generation
  const [highlightInput, setHighlightInput] = useState("");

  /** 1) Fetch Event */
  const fetchEvent = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const res = await axios.get(`/api/material/events/${eventId}/`);
      const evt = res.data;
      setEventData(evt);

      // build local param states from processing_params
      const initialParams = {};
      evt.performances.forEach(pf => {
        if (pf.processing_params) {
          initialParams[pf.id] = { ...pf.processing_params };
        } else {
          initialParams[pf.id] = {
            start_time:0,
            end_time:0,
            brightness:0,
            contrast:1.0,
            saturation:1.0,
            color_balance_r:0,
            color_balance_g:0,
            color_balance_b:0
          };
        }
      });
      setParamsByPerf(initialParams);

      // Preload whichever we show in the left side:
      // if best_thumbnail_touched => show that
      // else fallback to raw screenshot
      evt.performances.forEach(pf => {
        // We'll create a "displayUrl"
        let displayUrl = pf.best_thumbnail_touched;
        if (!displayUrl) {
          // fallback
          displayUrl = pf.screenshot_url;  
          // or if you store it differently, e.g. pf.raw_recording.screenshot
        }
        if (displayUrl) {
          loadImage(displayUrl)
            .then(img => {
              setImgCache(prev => ({ ...prev, [pf.id]: img }));
            })
            .catch(err => {
              console.warn("failed to load image for performance", pf.id, err);
            });
        }
      });

    } catch (err) {
      console.error(err);
      setError("Failed to load event data.");
    } finally {
      setLoading(false);
    }
  }, [eventId]);

  useEffect(() => {
    fetchEvent();
  }, [eventId, fetchEvent]);

  /** 2) Real-time color drawing to canvas (only if best_thumbnail is NOT set) */
  useEffect(() => {
    if (!eventData) return;
    eventData.performances.forEach(perf => {
      if (perf.best_thumbnail_touched) {
        // if we have best thumbnail, we do NOT do the canvas preview
        return;
      }
      const c = canvasRefs.current[perf.id];
      if (!c) return;
      const p = paramsByPerf[perf.id];
      const img = imgCache[perf.id];
      if (!p || !img) return;

      const ctx = c.getContext('2d');
      c.width  = img.naturalWidth;
      c.height = img.naturalHeight;
      ctx.drawImage(img, 0,0);
      const imageData = ctx.getImageData(0,0, c.width, c.height);

      const out = adjustColorsJS(imageData.data, c.width, c.height, {
        brightness: p.brightness,
        contrast: p.contrast,
        saturation: p.saturation,
        r: p.color_balance_r,
        g: p.color_balance_g,
        b: p.color_balance_b
      });
      imageData.data.set(out);
      ctx.putImageData(imageData, 0, 0);
    });
  }, [eventData, paramsByPerf, imgCache]);

  /** 3) Param changes */
  const handleParamChange = (perfId, field, value) => {
    setParamsByPerf(prev => ({
      ...prev,
      [perfId]: {
        ...prev[perfId],
        [field]: value,
      }
    }));
  };

  const handleSaveParams = async (perfId) => {
    try {
      const processing_params = paramsByPerf[perfId];
      await axios.post(
        `/api/material/performance/${perfId}/`,
        { processing_params },
        { headers: { 'Content-Type': 'application/json' } }
      );
      alert(`Parameters saved for performance ${perfId}`);
    } catch (err) {
      console.error(err);
      alert("Failed to save parameters.");
    }
  };

  const handleProcessFull = async (perfId) => {
    try {
      await axios.post(`/api/material/performance/${perfId}/process_full/`);
      alert(`Full video processing started for perf ${perfId}`);
    } catch (err) {
      console.error(err);
      alert("Failed to start process_full task.");
    }
  };
  
  const handleHighlights = async (perfId) => {
    try {
      await axios.post(`/api/material/performance/${perfId}/generate_highlights_only/`);
      alert(`Highlights generation started for perf ${perfId}`);
    } catch (err) {
      console.error(err);
      alert("Failed to start generate_highlights_only task.");
    }
  };
  
  const handleThumbnails = async (perfId) => {
    try {
      await axios.post(`/api/material/performance/${perfId}/generate_thumbnails_only/`);
      alert(`Thumbnails generation started for perf ${perfId}`);
    } catch (err) {
      console.error(err);
      alert("Failed to start generate_thumbnails_only task.");
    }
  };

  const handleUploadToYouTube = async (perfId) => {
    try {
      await axios.post(
        `/api/material/performance/${perfId}/upload_to_youtube/`,
        {},
        { headers: { 'Content-Type': 'application/json' } }
      );
      alert(`YouTube upload started for performance ${perfId}.`);
    } catch (err) {
      console.error(err);
      alert(`Failed to start YouTube upload for Performance ${perfId}`);
    }
  };

  const handleUploadToDrive = async (perfId) => {
    try {
      await axios.post(
        `/api/material/performance/${perfId}/upload_to_drive/`,
        {},
        { headers: { 'Content-Type': 'application/json' } }
      );
      alert(`Started Drive upload for performance ${perfId}`);
    } catch (err) {
      console.error(err);
      alert(`Failed to start Drive upload for perf ${perfId}.`);
    }
  };
  
  const handleEverything = async (perfId) => {
    try {
      await axios.post(`/api/material/performance/${perfId}/run_all_processing/`);
      alert(`All steps started for perf ${perfId}`);
    } catch (err) {
      console.error(err);
      alert("Failed to start run_all_processing task.");
    }
  };

  const handleApplyToAll = async (sourcePerfId) => {
    try {
      await axios.post(`/api/material/events/${eventId}/apply_params_from/${sourcePerfId}/`);
      alert("Parameters applied to all performances of this event.");
      fetchEvent();
    } catch (err) {
      console.error(err);
      alert("Failed to apply params to all.");
    }
  };

  const handleSetCover = async (perfId) => {
    /**
     * Example: we call some endpoint like:
     *  POST /api/material/performance/<perfId>/set_cover/
     * which will run `performance.generate_square_thumbnail()`
     */
    try {
      await axios.post(
        `/api/material/performance/${perfId}/set_cover/`,
        {}, // no body needed, presumably
        { headers: { 'Content-Type': 'application/json' } }
      );
      alert(`Cover generated for performance ${perfId}.`);
      // Possibly re-fetch or do any UI updates
    } catch (err) {
      console.error(err);
      alert(`Failed to set cover for perf ${perfId}`);
    }
  };

  const handleUploadToWebsite = async (perfId) => {
    /**
     * Example: we call e.g.:
     *  POST /api/material/performance/<perfId>/upload_to_website/
     * which triggers `update_snippet()` and `update_audio()` 
     * on the performance instance
     */
    try {
      await axios.post(
        `/api/material/performance/${perfId}/upload_to_website/`,
        {},
        { headers: { 'Content-Type': 'application/json' } }
      );
      alert(`Upload to website started for performance ${perfId}.`);
    } catch (err) {
      console.error(err);
      alert(`Failed to upload for perf ${perfId}.`);
    }
  };

// Reuse your helper or a short snippet:
function loadImage(url) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'anonymous'; // so we can draw it onto a <canvas>
    img.onload = () => resolve(img);
    img.onerror = (err) => reject(err);
    img.src = url;
  });
}

/** Decide if we want black or white text based on performance time < 18:00. */
function isBefore6PM(timeStr) {
  if (!timeStr) return true;
  const [hh] = timeStr.split(":");
  const hour = parseInt(hh, 10);
  return hour < 18;
}

async function handlePickBestThumbnail(perf, thumb) {
  try {
    // 1) Load the thumbnail image
    if (!thumb.image_url) {
      alert("This thumbnail has no image_url!");
      return;
    }
    const img = await loadImage(thumb.image_url);

    // 2) Create a canvas, draw the thumbnail
    const canvas = document.createElement('canvas');
    canvas.width  = img.naturalWidth;
    canvas.height = img.naturalHeight;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0);

    // 3) Figure out DJ name + color
    const djName = perf.dj_name || "Unknown DJ";
    const color = isBefore6PM(perf.time) ? 'black' : 'white';

    ctx.font = "45px sans-serif";
    ctx.fillStyle = color;
    ctx.textBaseline = "top";

    const metrics = ctx.measureText(djName);
    const textWidth = metrics.width;
    const padding = 25;
    const xPos = canvas.width - textWidth - padding;
    const yPos = padding;
    ctx.fillText(djName, xPos, yPos);

    // 4) Convert to Blob -> call your "pick_best_thumbnail" endpoint
    canvas.toBlob(async (blob) => {
      if (!blob) {
        alert("Failed to build processed thumbnail.");
        return;
      }
      const formData = new FormData();
      formData.append('processed_thumbnail', blob, 'best_thumbnail.jpg');

      /**
       * We assume an endpoint like:
       *   POST /api/material/performance/<perf.id>/pick_best_thumbnail/<thumb.id>/
       * It expects 'processed_thumbnail' in the request to store in best_thumbnail_touched
       * and also will remove other thumbnails from DB, set this one as best, etc.
       */
      const url = `/api/material/performance/${perf.id}/pick_best_thumbnail/${thumb.id}/`;
      await axios.post(url, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      alert("Best thumbnail updated, others removed!");
      // Possibly re-fetch your event data here
      // fetchEvent();
    }, 'image/jpeg', 0.9);

  } catch (err) {
    console.error(err);
    alert("Failed to pick best thumbnail.");
  }
}

  /** 5) Generate Highlights logic */
  const handleHighlightInputChange = (e) => {
    setHighlightInput(e.target.value);
  };
  const handleGenerateHighlights = async (perfId) => {
    if (!highlightInput.trim()) {
      alert("No highlight timestamps provided!");
      return;
    }
    try {
      await axios.post(
        `/api/material/performance/${perfId}/generate_highlights/`,
        { highlight_string: highlightInput },
        { headers: { 'Content-Type': 'application/json' } }
      );
      alert(`Highlights generation started for performance ${perfId}.`);
      setHighlightInput("");
    } catch (err) {
      console.error(err);
      alert("Failed to generate highlights.");
    }
  };


  // ---------- Rendering ------------
  if (loading) {
    return <Box textAlign="center" sx={{ mt:4 }}><CircularProgress /></Box>;
  }
  if (error) {
    return <Typography color="error">{error}</Typography>;
  }
  if (!eventData) {
    return <Typography>No event data found.</Typography>;
  }

  return (
    <Box className="detailContainer">
      {/* Top Bar */}
      <Box className="topBar">
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h4" sx={{ ml:2 }}>
          Event {eventData.id}: {eventData.event_name}
        </Typography>
      </Box>

      <Typography variant="body1" sx={{ mb:2 }}>
        Date: {eventData.date} | Program: {eventData.program}
      </Typography>

      {eventData.performances.length === 0 ? (
        <Typography>No performances for this event.</Typography>
      ) : (
        eventData.performances.map(perf => {
          const perfParams = paramsByPerf[perf.id] || {};

          const hasBestThumb = isValidUrlString(perf.best_thumbnail_touched);
          return (
            <Paper key={perf.id} className="performancePaper">
              <Typography variant="h6" gutterBottom>
                Performance {perf.id} - {perf.dj_name}
              </Typography>
              
              <Grid container spacing={2}>
                {/* Left side: if we have best_thumbnail_touched => show it
                    otherwise show canvas with screenshot preview */}
                  <Grid item xs={12} md={8} className="leftSide">
                    {hasBestThumb ? (
                      <Box>
                        <img
                          src={perf.best_thumbnail_touched}
                          alt="Best Thumbnail"
                          style={{ width:'100%', border:'1px solid #ccc' }}
                        />
                        <Typography variant="caption">
                          (DJ: {perf.dj_name || "Unknown DJ"})
                        </Typography>
                      </Box>
                    ) : (
                      perf.screenshot_url ? (
                        <canvas
                          ref={el => {
                            if (el) canvasRefs.current[perf.id] = el;
                          }}
                          style={{ width:'100%', border:'1px solid #ccc' }}
                        />
                      ) : (
                        <Box className="noScreenshot">
                          <Typography>No screenshot</Typography>
                        </Box>
                      )
                    )}
                  
                </Grid>

                {/* Right side: param sliders & actions */}
                <Grid item xs={12} md={4} className="rightSide">
                  <Box sx={{ mb:1 }}>
                    <TextField
                      label="Start Time (s)"
                      type="number"
                      size="small"
                      value={perfParams.start_time ?? 0}
                      onChange={e => handleParamChange(perf.id, 'start_time', parseInt(e.target.value)||0)}
                      sx={{ width:'120px', mr:2 }}
                    />
                    <TextField
                      label="End Time (s)"
                      type="number"
                      size="small"
                      value={perfParams.end_time ?? 0}
                      onChange={e => handleParamChange(perf.id, 'end_time', parseInt(e.target.value)||0)}
                      sx={{ width:'120px' }}
                    />
                  </Box>

                  <Box sx={{ mb:1 }}>
                    <Typography>Brightness ({perfParams.brightness})</Typography>
                    <Slider
                      min={-50} max={50} step={1}
                      value={perfParams.brightness ?? 0}
                      onChange={(e,v) => handleParamChange(perf.id, 'brightness', v)}
                    />
                  </Box>

                  <Box sx={{ mb:1}}>
                    <Typography>Contrast ({perfParams.contrast?.toFixed(2)})</Typography>
                    <Slider
                      min={0.8} max={1.5} step={0.01}
                      value={perfParams.contrast ?? 1.0}
                      onChange={(e,v) => handleParamChange(perf.id, 'contrast', v)}
                    />
                  </Box>

                  <Box sx={{ mb:1 }}>
                    <Typography>Saturation ({perfParams.saturation?.toFixed(2)})</Typography>
                    <Slider
                      min={0.5} max={2.0} step={0.1}
                      value={perfParams.saturation ?? 1.0}
                      onChange={(e,v) => handleParamChange(perf.id, 'saturation', v)}
                    />
                  </Box>

                  <Box sx={{ mb:1 }}>
                    <Typography>R Offset ({perfParams.color_balance_r})</Typography>
                    <Slider
                      min={-50} max={50} step={1}
                      value={perfParams.color_balance_r ?? 0}
                      onChange={(e,v) => handleParamChange(perf.id, 'color_balance_r', v)}
                    />
                  </Box>

                  <Box sx={{ mb:1 }}>
                    <Typography>G Offset ({perfParams.color_balance_g})</Typography>
                    <Slider
                      min={-50} max={50} step={1}
                      value={perfParams.color_balance_g ?? 0}
                      onChange={(e,v) => handleParamChange(perf.id, 'color_balance_g', v)}
                    />
                  </Box>

                  <Box sx={{ mb:1 }}>
                    <Typography>B Offset ({perfParams.color_balance_b})</Typography>
                    <Slider
                      min={-50} max={50} step={1}
                      value={perfParams.color_balance_b ?? 0}
                      onChange={(e,v) => handleParamChange(perf.id, 'color_balance_b', v)}
                    />
                  </Box>

                  <Box sx={{ mt:2 }}>
                    <Button
                      variant="contained" size="small"
                      sx={{ mr:1 }}
                      onClick={() => handleSaveParams(perf.id)}
                    >
                      Save Params
                    </Button>
                    <Button variant="contained" sx={{ mr:1 }}size="small" onClick={() => handleProcessFull(perf.id)}>
                      Process Full Video
                    </Button>

                    <Button variant="outlined" size="small" onClick={() => handleHighlights(perf.id)}>
                      Generate Highlights
                    </Button>

                    <Button variant="outlined" size="small" onClick={() => handleThumbnails(perf.id)}>
                      Generate Thumbnails
                    </Button>

                    <Button variant="outlined" size="small" color="secondary" onClick={() => handleEverything(perf.id)}>
                      Do Everything
                    </Button>
                    <Button
                      variant="outlined"
                      color="secondary"
                      size="small"
                      onClick={() => handleApplyToAll(perf.id)}
                    >
                      Apply to All
                    </Button>
                  </Box>

                  <Box sx={{ mt:2 }}>
                    <Button
                      variant="contained"
                      size="small"
                      color="secondary"
                      onClick={() => handleSetCover(perf.id)}
                    >
                      Set Cover
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      sx={{ ml:1 }}
                      onClick={() => handleUploadToWebsite(perf.id)}
                    >
                      Upload to Website
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => handleUploadToYouTube(perf.id)}
                    >
                      Upload to YouTube
                    </Button>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => handleUploadToDrive(perf.id)}
                    >
                      Upload to Drive
                    </Button>
                  </Box>
                </Grid>
              </Grid>

              {/* Single button to "Update Best Thumbnail" */}
              <Accordion sx={{ mt:3 }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="subtitle1">Thumbnails</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {perf.thumbnails && perf.thumbnails.length > 0 ? (
                    <Box className="thumbnailGrid">
                      {perf.thumbnails.map(thumb => (
                        <Box
                          key={thumb.id}
                          sx={{
                            border: thumb.is_best ? '3px solid blue' : '1px solid gray',
                            p:1
                          }}
                        >
                          <img
                            src={thumb.image_url} // or best to use a function that resolves full URL
                            alt="thumbnail"
                            className="thumbnailImage"
                          />
                          <Button
                            variant="outlined"
                            size="small"
                            onClick={() => handlePickBestThumbnail(perf, thumb)}
                          >
                            Pick as Best
                          </Button>
                        </Box>
                      ))}
                    </Box>
                  ) : (
                    <Typography>No thumbnails found.</Typography>
                  )}
                </AccordionDetails>
              </Accordion>

              {/* Generate Highlights */}
              <Accordion sx={{ mt:2 }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="subtitle1">Generate Highlights</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2" sx={{ mb:1 }}>
                    Provide start times & durations, e.g. <code>00:01:00, 60, 00:02:23, 45</code>
                  </Typography>
                  <TextField
                    label="Highlight Timestamps"
                    multiline
                    minRows={2}
                    fullWidth
                    value={highlightInput}
                    onChange={handleHighlightInputChange}
                    placeholder="00:01:00, 60, 00:02:23, 45..."
                    sx={{ mb:2 }}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleGenerateHighlights(perf.id)}
                  >
                    Generate Highlights
                  </Button>
                </AccordionDetails>
              </Accordion>
            </Paper>
          );
        })
      )}
    </Box>
  );
};

export default MaterialAdminDetail;
