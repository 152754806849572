// cropImage.js
const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.setAttribute('crossOrigin', 'anonymous'); // Avoid CORS issues
    image.onload = () => resolve(image);
    image.onerror = (error) => reject(error);
    image.src = url;
  });

export default async function getCroppedImg(
  imageSrc,
  pixelCrop,
  {
    outputFormat = 'image/jpeg', // default is JPEG
    maxWidth = 1200,             // keep or adjust the max width
  } = {}
) {
  // 1) Load image
  const image = await createImage(imageSrc);

  // 2) Determine original crop size
  let croppedWidth = pixelCrop.width;
  let croppedHeight = pixelCrop.height;

  // 3) If croppedWidth is over our maxWidth, scale it down
  if (croppedWidth > maxWidth) {
    const scaleFactor = maxWidth / croppedWidth;
    croppedWidth = maxWidth;
    croppedHeight = Math.floor(croppedHeight * scaleFactor);
  }

  // 4) Create a canvas with the final dimension
  const canvas = document.createElement('canvas');
  canvas.width = croppedWidth;
  canvas.height = croppedHeight;
  const ctx = canvas.getContext('2d');

  // 5) Draw the specified sub-section of the image
  ctx.drawImage(
    image,
    pixelCrop.x,         // srcX
    pixelCrop.y,         // srcY
    pixelCrop.width,     // srcWidth
    pixelCrop.height,    // srcHeight
    0,                   // destX
    0,                   // destY
    croppedWidth,        // destWidth (possibly scaled)
    croppedHeight        // destHeight (possibly scaled)
  );

  // 6) Convert canvas to Blob, using outputFormat
  return new Promise((resolve, reject) => {
    canvas.toBlob(
      (blob) => {
        if (!blob) {
          console.error('Canvas is empty');
          return reject('Canvas is empty');
        }
        resolve(blob);
      },
      outputFormat,
      1.0 // quality (for JPEG, ignored for PNG)
    );
  });
}
