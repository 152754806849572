import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import './ContentManage.css';
import './loading-overlay.css';

const PAGE_SIZE = 18; // Match your server-side pagination page size

const ContentManage = () => {
    const navigate = useNavigate();
    const location = useLocation();

    // Try to read page from query params or fallback to 1
    const searchParams = new URLSearchParams(location.search);
    const initialPage = parseInt(searchParams.get('page'), 10) || 1;

    const [events, setEvents] = useState([]);
    const [currentPage, setCurrentPage] = useState(initialPage);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [hasPreviousPage, setHasPreviousPage] = useState(false);
    const [totalPages, setTotalPages] = useState(1);
    const [loadingSets, setLoadingSets] = useState(false);
    const [count, setCount] = useState(0);

    useEffect(() => {
        fetchEvents(currentPage);
    }, [currentPage]);

    const fetchEvents = async (page = 1) => {
        setLoadingSets(true);
        try {
            const response = await axios.get('/api/content_manager/events/', {
                params: { page }
            });
            setEvents(response.data.results);
            setHasNextPage(!!response.data.next);
            setHasPreviousPage(!!response.data.previous);
            if (response.data.count) {
                setCount(response.data.count);
                const total = Math.ceil(response.data.count / PAGE_SIZE);
                setTotalPages(total);
            }
        } catch (error) {
            console.error('Error fetching events:', error);
        } finally {
            setLoadingSets(false);
        }
    };

    const handleEventClick = (eventId) => {
        // Navigate to detail page with current page in query param
        navigate(`events/${eventId}?page=${currentPage}`);
    };

    function isDarkColor(hexColor) {
        if (!hexColor) return false;
        const hex = hexColor.replace('#', '');
        const r = parseInt(hex.substr(0, 2), 16);
        const g = parseInt(hex.substr(2, 2), 16);
        const b = parseInt(hex.substr(4, 2), 16);
        const brightness = (r * 299 + g * 587 + b * 114) / 1000;
        return brightness < 128; // lower brightness = darker color
    }

    const handlePageClick = (pageNum) => {
        setCurrentPage(pageNum);
    };

    const filteredEvents = events.filter(event => {
        const eventDate = new Date(event.date);
        const today = new Date();
        return eventDate < today;
    });

    return (
        <div className="content-manage-container">
            {loadingSets && (
                <div className="loading-overlay">
                    <img src="static/img/loading.png" alt="Loading" className="loading-gif" />
                </div>
            )}
            <h1>Content Manager Dashboard</h1>

            <div className="events-grid">
                {events.map((event) => {
                    const darkBackground = isDarkColor(event.color);
                    const textColor = darkBackground ? '#fff' : '#000';
                    return (
                        <div
                            key={event.id}
                            className="event-box"
                            style={{ backgroundColor: event.color, color: textColor }}
                            onClick={() => handleEventClick(event.id)}
                        >
                            <div className="event-info">
                                <p><strong>Date:</strong> {event.date}</p>
                                <p><strong>Week No:</strong> {event.week_no}</p>
                                <p><strong>Event Name:</strong> {event.event_name}</p>
                                <p><strong>Location:</strong> {event.location.map(loc => loc.location_name).join(', ')}</p>
                                {((event.label && event.label.length > 0) || (event.partner && event.partner.length > 0)) && (
                                    <div className="labels-partners">
                                        {event.label && event.label.length > 0 && (
                                            <div className="labels">
                                                {event.label.map(label => (
                                                    <img
                                                        key={label.id}
                                                        src={label.label_logo}
                                                        alt={label.label_name}
                                                        className="logo-image"
                                                    />
                                                ))}
                                            </div>
                                        )}
                                        {event.partner && event.partner.length > 0 && (
                                            <div className="partners">
                                                {event.partner.map(partner => (
                                                    <img
                                                        key={partner.id}
                                                        src={partner.logo}
                                                        alt={partner.name}
                                                        className="logo-image"
                                                    />
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                            <div className="event-lineup">
                                {event.content_manager_performances && event.content_manager_performances.length > 0 && (
                                    <>
                                        <p><strong>Lineup:</strong></p>
                                        {event.content_manager_performances.map(performance => (
                                            <div key={performance.id} className="artist-row">
                                                <span className="artist-left">
                                                    {performance.time} - {performance.dj.map(dj => dj.name).join(', ')}
                                                </span>
                                                <span className="artist-right">
                                                    <small>{performance.publish_date}</small>
                                                </span>
                                            </div>
                                        ))}
                                    </>
                                )}
                            </div>
                        </div>
                    );
                })}
            </div>

            <div className="pagination-controls" style={{ marginTop: '20px' }}>
                <button onClick={() => handlePageClick(Math.max(currentPage - 1, 1))} disabled={!hasPreviousPage}>Previous</button>
                {/* Render page numbers */}
                {Array.from({ length: totalPages }, (_, i) => i + 1).map((pageNum) => (
                    <button
                        key={pageNum}
                        onClick={() => handlePageClick(pageNum)}
                        className={pageNum === currentPage ? 'active-page' : ''}
                        style={{fontWeight: pageNum === currentPage ? 'bold' : 'normal'}}
                    >
                        {pageNum}
                    </button>
                ))}
                <button onClick={() => handlePageClick(currentPage + 1)} disabled={!hasNextPage}>Next</button>
            </div>
        </div>
    );
};

export default ContentManage;
